import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectDialogService } from '../services/project.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PreventableEvent } from '@progress/kendo-angular-common';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
})
export class TagsListComponent extends DialogContentBase implements OnInit {
  @Input() tagsList: any[] = [];
  @Input() selectedtagsList: any[] = [];
  public formGroup: FormGroup;
  displayTagList: any[] = [];

  constructor(
    public dialog: DialogRef,
    private fb: FormBuilder,
  ) {
    super(dialog);
  }

  public ngOnInit(): void {
    this.formGroup = this.fb.group({
      tagName: [''],
    });

    this.tagsList.forEach(element => {
      const isExist = this.selectedtagsList.filter(x => x.id === element.id);
      if (isExist.length === 0) {
        this.displayTagList.push(element);
      }
    });
  }

  public onClose(ev: PreventableEvent): void {
    // this.formGroup.get('name').markAsTouched();
    // prevent dialog from closing on clicking the `X` (close) button
    ev.preventDefault();
  }

  public onCancelAction(): void {
    this.dialog.close(false);
  }

  public onConfirmAction(): void {
    this.dialog.close(false);
  }

  onTagSelected(value: any): void {
    // this.selectedTag = value;
    console.log('Selected tag:', value);
  }
}
