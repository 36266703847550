import { AuthService, ListService, PagedResultDto } from '@abp/ng.core';
import { UsersComponent, eIdentityComponents } from '@abp/ng.identity';
import {
  IdentityUserDto,
  IdentityRoleDto,
  GetIdentityUsersInput,
  IdentityUserService,
} from '@abp/ng.identity/proxy';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormGroup,
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { tap, switchMap, finalize, Subscription } from 'rxjs';
import { ePermissionManagementComponents } from '@abp/ng.permission-management';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { CommonService } from 'src/core/services';
import { NotificationTextMessage } from 'src/app/enum/notification';
export declare enum eFormComponets {
  FormInputComponent = 'FormInputComponent',
  FormCheckboxComponent = 'FormCheckboxComponent',
}

@Component({
  selector: 'app-custom-user',
  templateUrl: './custom-user.component.html',
  styleUrls: ['./custom-user.component.scss'],
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.Users,
    },
    {
      provide: UsersComponent,
      useExisting: CustomUserComponent,
    },
  ],
})
export class CustomUserComponent implements OnInit, OnDestroy {
  data: PagedResultDto<IdentityUserDto> = { items: [], totalCount: 0 };

  @ViewChild('modalContent', { static: false })
  modalContent!: TemplateRef<any>;

  form!: UntypedFormGroup;

  selected?: IdentityUserDto;

  selectedUserRoles?: IdentityRoleDto[];

  roles?: IdentityRoleDto[];

  visiblePermissions = false;

  providerKey?: string;

  isModalVisible?: boolean;

  modalBusy = false;

  permissionManagementKey = ePermissionManagementComponents.PermissionManagement;

  entityDisplayName: string;

  inputKey = 'FormCheckboxComponent';

  isDarkTheme = false;

  trackByFn: TrackByFunction<AbstractControl> = (index, item) => Object.keys(item)[0] || index;

  onVisiblePermissionChange = (event: boolean) => {
    this.visiblePermissions = event;
    if (!this.visiblePermissions) {
      this.isAddEditPermissionOpened = false;
    }
  };

  get roleGroups(): UntypedFormGroup[] {
    return ((this.form.get('roleNames') as UntypedFormArray)?.controls as UntypedFormGroup[]) || [];
  }

  @ViewChild('dialog') dialog: any;
  @ViewChild('dialogPermission') dialogPermission: any;
  editActionSubscription: Subscription;
  deletActionSubscription: Subscription;
  permissionActionSubscription: Subscription;
  isAddEditOpened = false;
  isAddEditPermissionOpened = false;
  searchText = '';

  constructor(
    public readonly list: ListService<GetIdentityUsersInput>,
    protected confirmationService: ConfirmationService,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    protected fb: UntypedFormBuilder,
    protected injector: Injector,
    private commonService: CommonService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {}

  open(): void {
    this.isAddEditOpened = true;
  }

  public close(status: string): void {
    this.isAddEditOpened = false;
  }

  closePermission(status: string): void {
    this.isAddEditPermissionOpened = false;
  }

  ngOnInit(): void {
    this.hookToQuery();

    this.isDarkTheme = localStorage.getItem('currentSelectedTheme') === 'dark';

    this.commonService.themeColorChange.subscribe(newTheme => {
      this.isDarkTheme = newTheme === 'dark';
    });
    this.editActionSubscription = this.commonService.editChange.subscribe(data => {
      this.edit(data);
    });
    this.permissionActionSubscription = this.commonService.permissionChange.subscribe(data => {
      this.openPermissionsModal(data);
    });
    this.deletActionSubscription = this.commonService.deleteChange.subscribe(data => {
      this.delete(data);
    });
  }

  ngOnDestroy(): void {
    this.editActionSubscription?.unsubscribe();
    this.deletActionSubscription?.unsubscribe();
    this.permissionActionSubscription?.unsubscribe();
  }

  buildForm(): void {
    const data = new FormPropData(this.injector, this.selected);
    this.form = generateFormFromProps(data);

    this.service.getAssignableRoles().subscribe(({ items }) => {
      this.roles = items;
      if (this.roles) {
        this.form.addControl(
          'roleNames',
          this.fb.array(
            this.roles.map(role =>
              this.fb.group({
                [role.name as string]: [
                  this.selected?.id
                    ? !!this.selectedUserRoles?.find(userRole => userRole.id === role.id)
                    : role.isDefault,
                ],
              })
            )
          )
        );
      }
    });
  }

  openModal(): void {
    this.buildForm();
    this.isModalVisible = true;
    this.open();
    this.commonService.astricValidationColor(this.cdr, this.elementRef, this.renderer);
  }

  add(): void {
    this.selected = {} as IdentityUserDto;
    this.selectedUserRoles = [] as IdentityRoleDto[];
    this.openModal();
  }

  edit(id: string): void {
    this.service
      .get(id)
      .pipe(
        tap(user => (this.selected = user)),
        switchMap(() => this.service.getRoles(id))
      )
      .subscribe(userRole => {
        this.selectedUserRoles = userRole.items || [];
        this.openModal();
      });
  }

  save(): void {
    if (!this.form.valid || this.modalBusy) return;
    this.modalBusy = true;

    const { roleNames = [] } = this.form.value;
    const mappedRoleNames =
      roleNames
        .filter((role: { [key: string]: any }) => !!role[Object.keys(role)[0]])
        .map((role: { [key: string]: any }) => Object.keys(role)[0]) || [];

    const { id } = this.selected || {};

    (id
      ? this.service.update(id, {
          ...this.selected,
          ...this.form.value,
          roleNames: mappedRoleNames,
        })
      : this.service.create({ ...this.form.value, roleNames: mappedRoleNames })
    )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(
        () => {
          this.isModalVisible = false;
          this.isAddEditOpened = false;
          this.list.get();
          this.toasterService.success(
            id
              ? NotificationTextMessage.userUpdatedMessage
              : NotificationTextMessage.userAddedMessage,
            '',
            this.commonService.toasterMessageConfiguration
          );
        },
        error => {
          this.toasterService.error(error.error.error.message, '', {});
        }
      );
  }

  delete(data: any): void {
    swal({
      title: 'Are you sure?',
      text: `User ${data.userName} will be deleted. Do you confirm that?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        this.service.delete(data.id).subscribe(() => {
          this.toasterService.success(
            'AbpUi::SuccessFully Deleted!',
            '',
            this.commonService.toasterMessageConfiguration
          );
          this.list.get();
        });
      }
    });
  }

  sort(data: any): void {
    const { prop, dir } = data.sorts[0];
    this.list.sortKey = prop;
    this.list.sortOrder = dir;
  }

  private hookToQuery(): void {
    this.list.hookToQuery(query => this.service.getList(query)).subscribe(res => (this.data = res));
  }

  openPermissionsModal(data: any): void {
    this.providerKey = data.id;
    this.entityDisplayName = data.userName;
    setTimeout(() => {
      this.visiblePermissions = true;
      this.isAddEditPermissionOpened = true;
    }, 0);
  }

  onSearch(event: any): void {
    if (event.keyCode === 13) {
      this.list.filter = this.searchText;
      this.list.get();
    }
  }
}
