<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ headerText }}</span>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="productForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Product Name" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="name" #groupName></kendo-textbox>
      <kendo-formerror *ngIf="productForm.controls.name.errors?.maxlength"
        >Error: Product Name cannot have more than 5000 character!</kendo-formerror
      >
      <kendo-formerror *ngIf="productForm.controls.name.errors?.required"
        >Error: Product Name is required!</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="addProject" text="Add Project" labelCssClass="k-form-label">
      </kendo-label>
      <kendo-multiselect
        #addProject
        [clearButton]="false"
        [filterable]="true"
        [checkboxes]="true"
        [data]="listItemData"
        textField="text"
        valueField="value"
        [ngModelOptions]="{ standalone: true }"
        formControlName="projectIds"
        (filterChange)="onProjectSearch($event)"
      ></kendo-multiselect>
    </kendo-formfield>
  </form>
</div>

<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
  <div [ngClass]="{ 'curser-not-allowd': productForm.invalid }" class="display-inline flex-0">
    <button kendoButton (click)="onSubmit()" [disabled]="productForm.invalid">
      {{ this.productId === 0 ? 'Create Product' : 'Update Product' }}
    </button>
  </div>
</kendo-dialog-actions>
