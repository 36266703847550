import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FileSelectComponent, FileState } from '@progress/kendo-angular-upload';
import { FileService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { NotificationMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-attach-task-file-popup',
  templateUrl: './attach-task-file-popup.component.html',
  styleUrls: ['./attach-task-file-popup.component.scss'],
})
export class AttachTaskFilePopupComponent {
  @Input() taskId: any;
  selectedFiles: any[] = [];
  taskName: any;
  constructor(
    public dialogRef: DialogRef,
    private fileService: FileService,
    private toasterService: ToasterService,
    public commonService: CommonService,
  ) {}

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  onFileSelect(event: any): void {
    this.selectedFiles.push(event);
  }

  onSave(): void {
    const myFormData = new FormData();
    for (let i = 0; i < this.selectedFiles.length; i++) {
      const file: File = this.selectedFiles[i].files[0].rawFile;
      myFormData.append('taskId', this.taskId);
      myFormData.append('fileStreamContents', file);
    }
    const param: any = {
      taskId: this.taskId,
      fileStreamContents: myFormData,
    };
    this.fileService.saveMultiple(param).subscribe(res => {
      this.toasterService.success(
        NotificationMessage.saveFileSuccessMsg,
        '',
        this.commonService.toasterMessageConfiguration,
      );
      this.commonService.onDialogClose(this.dialogRef, true);
    });
  }

  onFileRemove(index: number): void {
    this.selectedFiles.splice(index, 1);
  }

  fileShowButton(state: FileState): boolean {
    return state === FileState.Selected ? true : false;
  }

  fileRemove(fileSelect: FileSelectComponent, uid: string): void {
    fileSelect.removeFileByUid(uid);
  }

  getFileObjectURL(file: File): string {
    return URL.createObjectURL(file[0].rawFile);
  }
}
