import { Component, OnInit } from '@angular/core';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { AttachTaskFilePopupComponent } from '../attach-task-file-popup/attach-task-file-popup.component';
import { TimeLogPopupComponent } from '../time-log-popup/time-log-popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/core/services';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { TaskListService } from 'projects/task-service/src/lib/proxy/task-service/task-list';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ListService } from '@abp/ng.core';
import swal from 'sweetalert/dist/sweetalert.min.js';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActionTypeEnum } from 'src/app/enum/status-type-enum';
import { TaskEditPopupComponent } from './task-edit-popup/task-edit-popup.component';

export class TaskDetails {
  assignTo: any;
  startDate: Date;
  dueDate: Date;
  createdDate: Date | string;
  createdBy: string;
  updatedBy: string;
  updatedDate: Date;
  taskId: number;
  taskName: string;
  estimateHrs: string;
  taskListName: string;
  isStartDate: boolean;
  isDueDate: boolean;
  creatorId: any;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-add-task-detail',
  templateUrl: './add-task-detail.component.html',
  styleUrls: ['./add-task-detail.component.scss'],
})
export class AddTaskDetailComponent implements OnInit {
  //#region timmer
  isTimerRunning: boolean = false;
  startTime: Date;
  timerInterval: any;

  startDate: any;
  dueDate: any;
  endTime: Date;
  //#endregion
  public sorting = null;
  timerActionList = [
    { id: 1, iconClass: 'fa-solid fa-pen', text: 'Edit Task', actionTypeId: ActionTypeEnum.Edit },
    {
      id: 2,
      iconClass: 'fa-solid fa-download',
      text: 'Export Task Timelog',
      actionTypeId: ActionTypeEnum.Export,
    },
    {
      id: 3,
      iconClass: 'far fa-trash-alt',
      text: 'Delete Task',
      actionTypeId: ActionTypeEnum.Delete,
    },
  ];

  timeLogList: any[] = [];
  taskId: any;
  projectId: any;
  projectName: any;
  userList: any[];

  taskDetails: TaskDetails = new TaskDetails();
  actionTypeEnum = ActionTypeEnum;
  totalLoggedTime: string;
  totalLoggedNonBillableTime: string;
  totalLoggedBillableTime: string;

  constructor(
    private dialogService: DialogService,
    private _Activatedroute: ActivatedRoute,
    private taskService: TaskService,
    public commonService: CommonService,
    private timelogService: TimelogService,
    private taskListService: TaskListService,
    public readonly list: ListService<GetIdentityUsersInput>,
    protected service: IdentityUserService,
    private toasterService: ToasterService,
    private spinnerService: NgxSpinnerService,
    private router: Router,
  ) {}
  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      if (params.keys.length > 0) {
        this.taskId = +atob(params.get('taskId'));
        this.projectId = +atob(params.get('projectId'));
        this.projectName = atob(params.get('projectName'));
        this.getTaskListDetails();
        this.getAllTimeLogList();
        this.getUserList();
      }
    });

    this.commonService.allTimeLogListFromTimer$.subscribe(() => {
      this.getAllTimeLogList();
    });
  }

  onActionItemClicked(event: any): void {
    switch (event.actionTypeId) {
      case ActionTypeEnum.Edit:
        this.editTask();
        break;
      case ActionTypeEnum.Delete:
        this.removeTask();
        break;
      case ActionTypeEnum.Export:
        // Handle export action
        break;
      default:
        break;
    }
  }

  editTask(): void {
    const dialogRef = this.dialogService.open({
      content: TaskEditPopupComponent,
      width: 450,
    });
    const attachFileInfo = dialogRef.content.instance as TaskEditPopupComponent;
    attachFileInfo.taskId = this.taskId;
    attachFileInfo.projectId = this.projectId;
    attachFileInfo.setDataOnLoad();
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getTaskListDetails();
      }
    });
  }

  removeTask(): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + this.taskDetails.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();
        this.taskService.delete(this.taskId).subscribe(
          res => {
            this.spinnerService.hide();
            this.toasterService.success(
              NotificationMessage.deleteTaskSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.redirectToTaskList();
          },
          error => {
            this.spinnerService.hide();
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  redirectToTaskList(): void {
    var params = {
      projectId: btoa(this.projectId),
      projectName: btoa(this.projectName),
    };

    this.router.navigate(['task-list', params]);
  }

  getTaskListDetails(): void {
    this.spinnerService.show();
    this.taskService.get(this.taskId).subscribe(x => {
      this.taskDetails.isStartDate =
        x.startdate !== null && x.startdate !== undefined ? true : false;
      this.taskDetails.isDueDate = x.duedate !== null && x.duedate !== undefined ? true : false;
      this.taskDetails.taskName = x.name;
      this.taskDetails.assignTo = x.users;
      this.taskDetails.startDate = new Date(
        x.startdate !== null && x.startdate !== undefined ? x.startdate : '',
      );
      this.taskDetails.dueDate = new Date(
        x.duedate !== null && x.duedate !== undefined ? x.duedate : '',
      );
      this.taskDetails.createdDate = x.creationTime;
      this.taskDetails.createdBy = this.getCreatedBy(x.creatorId);
      this.taskDetails.updatedDate = new Date(x.lastModificationTime);
      this.taskDetails.updatedBy = x.lastModifierUserName;
      this.taskDetails.taskId = this.taskId;
      this.taskDetails.taskListName = x.taskListName;
      this.taskDetails.creatorId = x.creatorId;
      this.taskDetails.estimateHrs = this.commonService.getHoursAndMinutesFromTotalMinutes(
        x.estimateMinutes,
      );
      this.spinnerService.hide();
    });
  }

  openAttachedFile(): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
    });

    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = this.taskDetails.taskName;
    dialogRef.result.subscribe(res => {
      if (res) {
        this.getAllTimeLogList();
      }
    });
  }

  getCreatedBy(creatorId): any {
    this.service.getList(creatorId).subscribe(res => {
      if (res.items && res.items.length > 0) {
        const foundItem = res.items.find(item => creatorId === item.id);
        if (foundItem) {
          this.taskDetails.createdBy = foundItem.name;
          return foundItem.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  }

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getAllTimeLogList();
  }

  getAllTimeLogList(): void {
    this.spinnerService.show();

    const params = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: null,
      sorting: this.sorting,
    };
    this.timelogService.getList(params).subscribe(res => {
      this.spinnerService.hide();
      this.timeLogList = [];
      if (res.items.length > 0) {
        res.items.forEach((element: any) => {
          element.hours = this.removeTrailingZeros((element.timelogMinutes / 60).toFixed(3));
          if (element.taskId === this.taskId) {
            this.timeLogList.push(element);
          }
        });

        const billableLogs = this.timeLogList.filter(log => log.timelogIsBillable === 1);
        const nonBillableLogs = this.timeLogList.filter(log => log.timelogIsBillable !== 1);

        this.totalLoggedTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(this.timeLogList),
        );
        this.totalLoggedBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(billableLogs),
        );
        this.totalLoggedNonBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(nonBillableLogs),
        );
      }
    });
  }

  calculateTotalTime(logs: any[]): number {
    return logs.reduce((total, log) => total + log.timelogMinutes, 0);
  }

  removeTrailingZeros(str: string): string {
    return str.replace(/(\.0+|(?<=\..*?)0+)$/, '');
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    this.startTime = new Date();
    this.endTime = new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.createdBy = this.taskDetails.createdBy;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.creatorId = this.taskDetails.creatorId;
    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getAllTimeLogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  onStartTimerClick() {
    const timerData = {
      showTimer: true,
      taskId: this.taskId,
      projectId: this.projectId,
    };

    this.commonService.showTimerPopup(timerData);
  }

  onEdit(data, taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
    });
    document.body.style.overflow = 'hidden';

    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.createdBy = this.taskDetails.createdBy;
    dialogRef.content.instance.creatorId = this.taskDetails.creatorId;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.result.subscribe((res: any) => {
      if (res) {
        if (res.confirmed) {
          this.getAllTimeLogList();
        }
        document.body.style.overflow = 'scroll';
      }
    });
  }

  onDelete(data: any): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + data.timelogDescription + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getAllTimeLogList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  getUserList() {
    this.list
      .hookToQuery(query => this.service.getList(query))
      .subscribe(res => {
        this.userList = res.items;
      });
  }

  getCreatedByName(createdById) {
    if (!this.userList) {
      return null;
    }
    const foundItem = this.userList.find(item => createdById === item.id);
    if (foundItem) {
      return foundItem.name;
    } else {
      return null;
    }
  }
}
