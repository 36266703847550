<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>{{ headerText }}</div>
</kendo-dialog-titlebar>
<div>
  <form [formGroup]="companyForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="companyName" text="Company Name" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="companyName"
        aria-placeholder="Company Name"
        #companyName
      ></kendo-textbox>
      <kendo-formerror *ngIf="companyForm.controls.companyName.errors?.maxlength"
        >Error: Company Name cannot be more than 255 character!</kendo-formerror
      >
      <kendo-formerror *ngIf="companyForm.controls.companyName.errors?.required"
        >Error: Company Name is required!</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="website" text="Website" labelCssClass="k-form-label"></kendo-label>
      <kendo-textbox formControlName="website" aria-placeholder="Website" #website></kendo-textbox>
      <kendo-formerror *ngIf="companyForm.controls.website.errors?.maxlength"
        >Error: Website cannot be more than 255 character!</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="email" text="Email" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="email" aria-placeholder="email" #email></kendo-textbox>
      <kendo-formerror *ngIf="companyForm.controls.email.errors?.required"
        >Error: Email is required!</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="phone" text="Phone" labelCssClass="k-form-label"></kendo-label>
      <kendo-textbox formControlName="phone" aria-placeholder="phone" #phone></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="mb-20" *ngIf="false">
      <kendo-label text="Logo" labelCssClass="k-form-label"></kendo-label>
      <fieldset class="k-form-fieldset">
        <kendo-fileselect
          [multiple]="false"
          #myFileSelect="kendoFileSelect"
          (select)="onFileSelect($event)"
          formControlName="companyLogo"
        >
          <ng-template kendoFileSelectFileTemplate let-files let-i="index" let-state="state">
            <div class="d-flex gap-5 wp-100">
              <div *ngIf="files" class="file-container">
                <img
                  [src]="getFileObjectURL(files)"
                  class="img-preview"
                  alt="Image Preview"
                  height="25"
                  width="50"
                />
              </div>
              <div class="wp-100 d-flex align-items-center">
                {{ files[0].name }}
              </div>
            </div>
            <div class="delete-container">
              <a *ngIf="fileShowButton(state)" (click)="fileRemove(myFileSelect, files[0].uid)">
                <i class="far fa-trash-alt"></i>
              </a>
            </div>
          </ng-template>
        </kendo-fileselect>
      </fieldset>
    </kendo-formfield>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
  <div [ngClass]="{ 'curser-not-allowd': companyForm.invalid }" class="display-inline flex-0">
    <button kendoButton (click)="onSaveCompany()" [disabled]="companyForm.invalid">
      {{ submitButtonText }}
    </button>
  </div>
</kendo-dialog-actions>
