<kendo-dialog-titlebar (close)="onCloseDialog()">
  <div>{{ headerText }}</div>
</kendo-dialog-titlebar>
<div>
  <form [formGroup]="categoryForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Category Name" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox
        formControlName="categoryName"
        placeholder="Category Name"
        #groupName
      ></kendo-textbox>

      <kendo-formerror *ngIf="categoryForm.controls.categoryName.errors?.maxlength"
        >Error: Category Name cannot be more than 50 character!</kendo-formerror
      >
      <kendo-formerror *ngIf="categoryForm.controls.categoryName.errors?.required"
        >Error: Category Name is required!</kendo-formerror
      >
    </kendo-formfield>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
  <div [ngClass]="{ 'curser-not-allowd': categoryForm.invalid }" class="display-inline flex-0">
    <button kendoButton (click)="onSaveCategory()" [disabled]="categoryForm.invalid">
      {{ submitButtonText }}
    </button>
  </div>
</kendo-dialog-actions>
