import type { CompanyDto } from './models';
import type {
  CreateProjectDto,
  GetProjectListInputDto,
  ProjectDto,
  ProjectSummaryDto,
  UpdateProjectDto,
} from './projects/dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  apiName = 'ProjectService';

  checkProjectName = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/project/nameExists/${name}`,
      },
      { apiName: this.apiName, ...config },
    );

  create = (input: CreateProjectDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectDto>(
      {
        method: 'POST',
        url: '/api/project',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/project/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectDto>(
      {
        method: 'GET',
        url: `/api/project/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getCompaniesLookup = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ListResultDto<CompanyDto>>(
      {
        method: 'GET',
        url: '/api/project/lookup/companies',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetProjectListInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: '/api/project',
        params: {
          includeProjectUserInfo: input.includeProjectUserInfo,
          include: input.include,
          fields: input.fields,
          orderBy: input.orderBy,
          orderMode: input.orderMode,
          orderByCustomFieldId: input.orderByCustomFieldId,
          filter: input.filter,
          searchCompanies: input.searchCompanies,
          projectCategoryIds: input.projectCategoryIds,
          projectHealths: input.projectHealths,
          projectTagIds: input.projectTagIds,
          excludeTagIds: input.excludeTagIds,
          projectOwnerIds: input.projectOwnerIds,
          projectCompanyIds: input.projectCompanyIds,
          onlyStarredProjects: input.onlyStarredProjects,
          hideObservedProjects: input.hideObservedProjects,
          includeCounts: input.includeCounts,
          projectStatuses: input.projectStatuses,
          iIncludeCustomFields: input.iIncludeCustomFields,
          searchByLetter: input.searchByLetter,
          searchTerm: input.searchTerm,
          statusSearchTerm: input.statusSearchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListByPortfolioId = (
    portfolioId: number,
    input: GetProjectListInputDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: `/api/project/getListByPortfolio/${portfolioId}`,
        params: {
          includeProjectUserInfo: input.includeProjectUserInfo,
          include: input.include,
          fields: input.fields,
          orderBy: input.orderBy,
          orderMode: input.orderMode,
          orderByCustomFieldId: input.orderByCustomFieldId,
          filter: input.filter,
          searchCompanies: input.searchCompanies,
          projectCategoryIds: input.projectCategoryIds,
          projectHealths: input.projectHealths,
          projectTagIds: input.projectTagIds,
          excludeTagIds: input.excludeTagIds,
          projectOwnerIds: input.projectOwnerIds,
          projectCompanyIds: input.projectCompanyIds,
          onlyStarredProjects: input.onlyStarredProjects,
          hideObservedProjects: input.hideObservedProjects,
          includeCounts: input.includeCounts,
          projectStatuses: input.projectStatuses,
          iIncludeCustomFields: input.iIncludeCustomFields,
          searchByLetter: input.searchByLetter,
          searchTerm: input.searchTerm,
          statusSearchTerm: input.statusSearchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getListByProjectId = (
    productId: number,
    input: GetProjectListInputDto,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, PagedResultDto<ProjectDto>>(
      {
        method: 'GET',
        url: `/api/project/getListByProduct/${productId}`,
        params: {
          includeProjectUserInfo: input.includeProjectUserInfo,
          include: input.include,
          fields: input.fields,
          orderBy: input.orderBy,
          orderMode: input.orderMode,
          orderByCustomFieldId: input.orderByCustomFieldId,
          filter: input.filter,
          searchCompanies: input.searchCompanies,
          projectCategoryIds: input.projectCategoryIds,
          projectHealths: input.projectHealths,
          projectTagIds: input.projectTagIds,
          excludeTagIds: input.excludeTagIds,
          projectOwnerIds: input.projectOwnerIds,
          projectCompanyIds: input.projectCompanyIds,
          onlyStarredProjects: input.onlyStarredProjects,
          hideObservedProjects: input.hideObservedProjects,
          includeCounts: input.includeCounts,
          projectStatuses: input.projectStatuses,
          iIncludeCustomFields: input.iIncludeCustomFields,
          searchByLetter: input.searchByLetter,
          searchTerm: input.searchTerm,
          statusSearchTerm: input.statusSearchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getProjectSummary = (input: GetProjectListInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectSummaryDto>(
      {
        method: 'GET',
        url: '/api/project/summary',
        params: {
          includeProjectUserInfo: input.includeProjectUserInfo,
          include: input.include,
          fields: input.fields,
          orderBy: input.orderBy,
          orderMode: input.orderMode,
          orderByCustomFieldId: input.orderByCustomFieldId,
          filter: input.filter,
          searchCompanies: input.searchCompanies,
          projectCategoryIds: input.projectCategoryIds,
          projectHealths: input.projectHealths,
          projectTagIds: input.projectTagIds,
          excludeTagIds: input.excludeTagIds,
          projectOwnerIds: input.projectOwnerIds,
          projectCompanyIds: input.projectCompanyIds,
          onlyStarredProjects: input.onlyStarredProjects,
          hideObservedProjects: input.hideObservedProjects,
          includeCounts: input.includeCounts,
          projectStatuses: input.projectStatuses,
          iIncludeCustomFields: input.iIncludeCustomFields,
          searchByLetter: input.searchByLetter,
          searchTerm: input.searchTerm,
          statusSearchTerm: input.statusSearchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  setOwner = (id: number, ownerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'PUT',
        url: `/api/project/${id}/owner/${ownerId}`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: UpdateProjectDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProjectDto>(
      {
        method: 'PUT',
        url: `/api/project/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
