import { AccountConfigModule } from '@abp/ng.account/config';
import { CoreModule, ListService } from '@abp/ng.core';
import { registerLocale } from '@abp/ng.core/locale';
import { IdentityConfigModule } from '@abp/ng.identity/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { TenantManagementConfigModule } from '@abp/ng.tenant-management/config';
import { ThemeLeptonXModule } from '@abp/ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@abp/ng.theme.lepton-x/layouts';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { ApiInterceptor } from '@abp/ng.core';
import { YourApiInterceptor } from 'projects/core/src/lib//interceptors/api.interceptor';
import { SharedModule } from './shared/shared.module';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import {
  AddCategoryComponent,
  AddCompanyPopupComponent,
  AddPortfolioComponent,
  AddProductComponent,
  AddUserComponent,
  CategoryListComponent,
  CompanyListComponent,
  PortfolioComponent,
  ProductComponent,
  ProjectCategoryComponent,
  UserListComponent,
} from './modules';
import {
  CustomExtensibleTableComponent,
  CustomManageFeatureComponent,
  CustomPermissionComponent,
  CustomRolesComponent,
  CustomUserComponent,
  CustomeTenantComponent,
  CustomAddTenantComponent,
  CustomEmailComponent,
  CustomSettingComponent,
  CustomSettingFeaturesComponent,
} from './shared';
import { CommonService } from 'src/core/services';
import { PageModule } from '@abp/ng.components/page';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { AddTaskListComponent } from 'tasks/task/src/pages/task/task-list/add-task-list/add-task-list.component';
import { DatePipe } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DashboardCountPopupComponent } from './modules/dashboard/dashboard-count-popup/dashboard-count-popup.component';
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot(),
    AccountConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    TenantManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    FeatureManagementModule.forRoot(),
    PageModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxSpinnerModule,
    // ProjectConfigModule.forRoot(),
    // TaskConfigModule.forRoot(),
    // CategoryConfigModule.forRoot(),
    // CompanyConfigModule.forRoot(),
    // PeopleConfigModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    CategoryListComponent,
    AddCategoryComponent,
    ProjectCategoryComponent,
    CompanyListComponent,
    AddCompanyPopupComponent,
    CustomExtensibleTableComponent,
    CustomManageFeatureComponent,
    CustomPermissionComponent,
    CustomRolesComponent,
    CustomUserComponent,
    CustomeTenantComponent,
    CustomAddTenantComponent,
    CustomEmailComponent,
    CustomSettingComponent,
    CustomSettingFeaturesComponent,
    PortfolioComponent,
    AddPortfolioComponent,
    ProductComponent,
    AddProductComponent,
    UserListComponent,
    AddUserComponent,
    DashboardCountPopupComponent,
  ],
  exports: [NgxSpinnerModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    APP_ROUTE_PROVIDER,
    {
      provide: ApiInterceptor,
      useClass: YourApiInterceptor,
    },
    CommonService,
    ListService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
