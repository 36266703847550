import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CreateUpdatePortfolioDto } from '@proxy/project-service/portfolios/dtos';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  PortfolioService,
  ProjectService,
} from 'projects/project-service/src/lib/proxy/project-service';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-add-portfolio',
  templateUrl: './add-portfolio.component.html',
  styleUrls: ['./add-portfolio.component.scss'],
})
export class AddPortfolioComponent {
  portfolioForm: FormGroup;
  @Input() portfolioId: any;
  headerText: string;
  submitButtonText: string;
  listItemData: any;
  items: any[] = [];
  listItems: any[] = [];
  public value: any;
  constructor(
    public readonly list: ListService,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    private projectService: ProjectService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private portfolioService: PortfolioService,
  ) {
    this.setForm();
  }

  setOnLoadData(): void {
    this.headerText =
      this.portfolioId === undefined ? 'Create a new Portfolio' : 'Update Portfolio';
    this.submitButtonText =
      this.portfolioId === undefined ? 'Create Portfolio' : 'Update Portfolio';

    this.getProjectList();
  }

  getProjectList(): void {
    const ProjectStreamCreator = query => this.projectService.getList(query);
    this.list.hookToQuery(ProjectStreamCreator).subscribe(response => {
      this.items = response.items;
      this.listItems = this.items.map(project => ({ text: project.name, value: project.id }));
      this.listItemData = this.listItems.slice();
      if (this.portfolioId > 0) {
        this.spinnerService.show();
        this.getDataByPortfolioId();
      }
    });
  }

  getDataByPortfolioId(): void {
    this.portfolioService.get(this.portfolioId).subscribe(res => {
      let selectedProjectList: any[] = [];
      if (res.projectIds.length > 0) {
        res.projectIds.forEach(element => {
          const data = this.listItems.find(x => x.value === element);
          if (data) {
            selectedProjectList.push(data);
          }
        });
      }
      this.portfolioForm.patchValue({
        name: res.name,
        addProject: selectedProjectList,
      });
      this.spinnerService.hide();
    });
  }

  setForm(): void {
    this.portfolioForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
      addProject: new FormControl([]),
    });
  }

  onPortfolioSubmit(): void {
    if (this.portfolioForm.invalid) {
      this.portfolioForm.controls.name.markAsTouched();
      return;
    }

    this.spinnerService.show();

    const projectIds = this.portfolioForm.value.addProject.map(item => item.value);

    const param: CreateUpdatePortfolioDto = {
      name: this.portfolioForm.value.name,
      status: 0,
      projectIds: projectIds,
    };

    if (this.portfolioId !== null && this.portfolioId !== undefined) {
      this.portfolioService.update(this.portfolioId, param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.commonService.onDialogClose(this.dialogRef, true);
            this.toasterService.success(
              NotificationTextMessage.portfolioUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.portfolioService.create(param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.commonService.onDialogClose(this.dialogRef, true);
            this.toasterService.success(
              NotificationTextMessage.portfolioAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  public onProjectSearch(searchTerm: string): void {
    const contains = (value: string) => (item: { text: string; value: number }) =>
      item.text.toLowerCase().includes(value.toLowerCase());

    this.listItemData = this.listItems.filter(contains(searchTerm));
  }
}
