import { mapEnumToOptions } from '@abp/ng.core';

export enum TaskListStatusType {
  Deleted = 0,
  Completed = 1,
  ReOpened = 2,
  New = 3,
}

export const taskListStatusTypeOptions = mapEnumToOptions(TaskListStatusType);
