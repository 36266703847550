import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateTaskListDto } from '../dtos/models';
import type { GetTaskListInputDto, TaskListDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TaskListService {
  apiName = 'TaskService';

  create = (input: CreateUpdateTaskListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskListDto>(
      {
        method: 'POST',
        url: '/api/tasklist',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/tasklist/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskListDto>(
      {
        method: 'GET',
        url: `/api/tasklist/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetTaskListInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TaskListDto>>(
      {
        method: 'GET',
        url: '/api/tasklist',
        params: {
          projectId: input.projectId,
          showMilestones: input.showMilestones,
          getCompleteCount: input.getCompleteCount,
          getNewDefaultTask: input.getNewDefaultTask,
          searchTerm: input.searchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateTaskListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskListDto>(
      {
        method: 'PUT',
        url: `/api/tasklist/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
