import { ToasterService } from '@abp/ng.theme.shared';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateUpdateTimelogDto } from 'projects/task-service/src/lib/proxy/task-service/dtos';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-time-log-popup',
  templateUrl: './time-log-popup.component.html',
  styleUrls: ['./time-log-popup.component.scss'],
})
export class TimeLogPopupComponent implements OnInit {
  currentDate = new Date();
  @Input() startTime: any;
  @Input() endTime: any;
  @Input() taskId: any;
  @Input() projectId: any;
  @Input() timeLogId: any;
  @Input() taskName: any;
  @Input() createdBy: any;
  @Input() creatorId: any;

  public popupClass = 'timer-picker-popup';
  timeSpent: string;
  totalSpentMinutes: number;
  logTimeForm: FormGroup;
  isEdit = false;
  logTimeTitle = 'Log Time';

  constructor(
    public dialogRef: DialogRef,
    private timelogService: TimelogService,
    private toasterService: ToasterService,
    public commonService: CommonService,
    private datePipe: DatePipe,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    if (this.timeLogId) {
      this.isEdit = true;
      this.logTimeTitle = 'Update Log Time';
      this.editTimeLogForm(this.timeLogId);
    }
    this.setForm();
  }

  updateDate(date: Date): void {
    this.currentDate = date;
    this.logTimeForm.get('date').setValue(date);
  }

  setForm(): void {
    this.logTimeForm = new FormGroup({
      date: new FormControl(this.currentDate),
      startTime: new FormControl(),
      endTime: new FormControl(),
      timeSpent: new FormControl(''),
      billable: new FormControl(''),
      description: new FormControl('', Validators.maxLength(255)),
      taskComplete: new FormControl(''),
    });
    this.logTimeForm.setValidators(this.timeRangeValidator);
  }

  timeRangeValidator(group: FormGroup) {
    const startTime = group.get('startTime').value;
    const endTime = group.get('endTime').value;

    if (startTime && endTime && startTime >= endTime) {
      return { invalidTimeRange: true };
    }

    return null;
  }

  editTimeLogForm(timeLogId): void {
    this.timelogService.get(timeLogId).subscribe(res => {
      const convertedUTCDateToIST = this.datePipe.transform(
        new Date(res.timelogDatetime),
        'yyyy-MM-dd HH:mm:ss',
        '+0530',
      );

      var getStartTime = convertedUTCDateToIST.slice(11, 23);
      const startTimeParts = getStartTime.split(':');
      const hrs = parseInt(startTimeParts[0], 10);
      const mins = parseInt(startTimeParts[1], 10);

      const startTimeDate = new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd'));
      startTimeDate.setHours(hrs);
      startTimeDate.setMinutes(mins);

      var getEndTime = this.commonService.getEndDate(convertedUTCDateToIST, res.timelogMinutes);

      this.logTimeForm.patchValue({
        date: new Date(this.datePipe.transform(convertedUTCDateToIST, 'yyyy-MM-dd')),
        timeSpent: res.timelogMinutes,
        description: res.timelogDescription,
        billable: res.timelogIsBillable,
        startTime: startTimeDate,
        endTime: getEndTime,
      });
    });
  }

  getEndDate(data): any {
    let timelogDatetime = new Date(data.timelogDatetime);
    timelogDatetime.setMinutes(timelogDatetime.getMinutes() + data.timelogMinutes);
    return timelogDatetime;
  }

  getStartDate(data) {
    const timelogDatetime = new Date(data.timelogDatetime);
    const timelogStartTime = new Date(timelogDatetime.getTime() - data.timelogHasStartTime * 60000); // Convert minutes to milliseconds
    const startTimeWithSubtraction = new Date(
      timelogStartTime.getTime() + data.timelogMinutes * 60000,
    );
    return startTimeWithSubtraction;
  }

  getTimelogDatetime(data): any {
    var getDate = new Date(this.datePipe.transform(data.date, 'yyyy-MM-dd'));
    var datePart = getDate.toLocalISOString().slice(0, 10);
    var timePart = data.startTime.toLocalISOString().slice(11, 23);
    var newDate = datePart + 'T' + timePart + 'Z';

    return newDate;
  }

  saveLogTime(data): void {
    this.spinnerService.show();

    if (!this.logTimeForm.hasError('invalidTimeRange')) {
      const param: CreateUpdateTimelogDto = {
        taskId: this.taskId,
        projectId: this.projectId,
        timelogDatetime: this.getTimelogDatetime(data),
        timelogMinutes: this.totalSpentMinutes,
        timelogDescription: data.description,
        timelogIsBillable: data.billable === true ? 1 : 0,
        timelogHasStartTime: this.totalSpentMinutes,
      };

      if (!this.isEdit) {
        this.timelogService.create(param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.commonService.onDialogClose(this.dialogRef, true);
              this.toasterService.success(
                NotificationMessage.timelogAddedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      } else {
        this.timelogService.update(this.timeLogId, param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.commonService.onDialogClose(this.dialogRef, true);
              this.toasterService.success(
                NotificationMessage.timelogUpdatedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      }
    } else {
      this.spinnerService.hide();
      this.toasterService.error(
        NotificationTextMessage.addValidateDateErrorMessage,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  calculateTimeSpent() {
    if (this.startTime && this.endTime) {
      const start = new Date(this.startTime);
      const end = new Date(this.endTime);
      const diff = end.getTime() - start.getTime();

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.totalSpentMinutes = Math.floor(diff / (1000 * 60));
      this.timeSpent = `${hours}:${minutes}`;
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }
}
