import { mapEnumToOptions } from '@abp/ng.core';

export enum TaskStatusType {
  NewTaskDefaults = 0,
  Deleted = 1,
  Completed = 2,
  ReOpened = 3,
  New = 4,
}

export const taskStatusTypeOptions = mapEnumToOptions(TaskStatusType);
