import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  CompanyService,
  PortfolioService,
} from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent {
  peopleForm: FormGroup;
  @Input() peopleId: any;
  headerText: string;
  submitButtonText: string;

  items: any[] = [];
  listItems: any[] = [];
  public value: any;
  constructor(
    public readonly list: ListService,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    private companyService: CompanyService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    private portfolioService: PortfolioService,
  ) {
    this.setForm();
    this.getCompanyList();
  }

  setOnLoadData(): void {
    this.headerText = this.peopleId === undefined ? 'Add new User' : 'Edit User';
    this.submitButtonText = this.peopleId === undefined ? 'create' : 'update';

    if (this.peopleId > 0) {
      this.getCompanyList();
    }
  }

  getCompanyList(): void {
    const ProjectStreamCreator = query => this.companyService.getList(query);
    this.list.hookToQuery(ProjectStreamCreator).subscribe(response => {
      this.items = response.items;
      this.listItems = this.items.map(project => ({ text: project.name, value: project.id }));
    });
  }

  setForm(): void {
    this.peopleForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      jobTitle: new FormControl(),
      company: new FormControl(),
      team: new FormControl(),
      email: new FormControl(),
    });
  }

  onUserSubmit(): void {
    this.spinnerService.show();
    if (this.peopleForm.valid) {
      const projectIds = this.peopleForm.value.addProject.map(item => item.value);

      const param: any = {
        name: this.peopleForm.value.name,
        status: 0,
        projectIds: projectIds,
      };

      if (this.peopleId !== null && this.peopleId !== undefined) {
        this.portfolioService.update(this.peopleId, param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.commonService.onDialogClose(this.dialogRef, true);
              this.toasterService.success(
                NotificationTextMessage.userUpdatedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      } else {
        this.portfolioService.create(param).subscribe(
          res => {
            if (res) {
              this.spinnerService.hide();
              this.commonService.onDialogClose(this.dialogRef, true);
              this.toasterService.success(
                NotificationTextMessage.userAddedMessage,
                '',
                this.commonService.toasterMessageConfiguration,
              );
            }
          },
          err => {
            this.spinnerService.hide();
          },
        );
      }
    } else {
      this.toasterService.error(
        NotificationTextMessage.formInvalid,
        '',
        this.commonService.toasterMessageConfiguration,
      );
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }
}
