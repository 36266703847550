import { mapEnumToOptions } from '@abp/ng.core';

export enum TaskTypeStatus {
  OpenTasks = 0,
  CloseTasks = 1,
  OpenBugs = 2,
  CloseBugs = 3,
}

export const taskTypeStatusOptions = mapEnumToOptions(TaskTypeStatus);
