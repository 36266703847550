<nb-card *ngIf="!isSaved" class="tenant-card">
  <nb-card-header>New Tenant</nb-card-header>
  <nb-card-body>
    <form [formGroup]="tenantForm" class="k-form k-form-md">
      <div class="row mb-3">
        <div class="form-field col-12">
          <label for="message">Tenant Name</label><span class="require-field"> * </span>
          <input
            nbInput
            fullWidth
            type="text"
            class="form-control input-full-width size-medium status-basic shape-rectangle"
            placeholder="Tenant Name"
            formControlName="name"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="form-field col-12">
          <label for="message">Admin Email Address</label><span class="require-field"> * </span>
          <input
            nbInput
            fullWidth
            type="email"
            class="form-control input-full-width size-medium status-basic shape-rectangle"
            placeholder="Admin Email Address"
            formControlName="adminEmail"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="form-field col-12">
          <label for="message">Admin Password</label><span class="require-field"> * </span>
          <div class="input-group tenant-password">
            <input
              nbInput
              type="{{ passwordVisible ? 'text' : 'password' }}"
              class="form-control password-input input-full-width size-medium status-basic shape-rectangle"
              placeholder="Admin Password"
              formControlName="adminPwd"
            />
            <button class="btn password-btn" type="button" (click)="togglePasswordVisibility()">
              <i class="fa" [ngClass]="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <div class="text-right">
      <button
        nbButton
        status="success"
        class="status-success appearance-filled size-medium shape-rectangle"
        (click)="save()"
        [disabled]="tenantForm?.invalid"
        [ngClass]="tenantForm?.invalid ? 'btn-disabled' : ''"
      >
        {{ 'AbpTenantManagement::Save' | abpLocalization }}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
<!-- <ngx-spinner type="ball-scale-multiple"></ngx-spinner> -->

<div *ngIf="isSaved" class="d-flex justify-content-center align-items-center h-100">
  <div class="Response-conatiner">
    <i class="fas fa-circle-check success-icon"></i>
    <p class="mb-0 p-success">Tenant created successfully</p>
    <p class="mb-0 p-email">Please check your Email Id</p>
  </div>
</div>
