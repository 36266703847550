import { ABP } from '@abp/ng.core';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { Component, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-custom-setting',
  templateUrl: './custom-setting.component.html',
  styleUrls: ['./custom-setting.component.scss'],
})
export class CustomSettingComponent implements OnDestroy, OnInit {
  private subscription = new Subscription();
  settings: ABP.Tab[] = [];

  selected!: ABP.Tab;

  trackByFn: TrackByFunction<ABP.Tab> = (_, item) => item.name;

  visibleFeatures = false;
  providerKey: string;

  constructor(private settingTabsService: SettingTabsService) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.subscription.add(
      this.settingTabsService.visible$.subscribe(settings => {
        this.settings = settings;
        if (!this.selected) this.selected = this.settings[0];
      }),
    );
  }

  openFeaturesModal() {
    this.visibleFeatures = true;
  }

  onVisibleFeaturesChange = (value: boolean) => {
    this.visibleFeatures = value;
  };

  closeFeature(status: string): void {
    this.visibleFeatures = false;
  }
}
