<div class="q-content">
  <div class="q-content-bg pb-0">
    <div>
      <div class="border-bottom">
        <p class="font-size-18 fw-500 mb-20 d-flex gap-15 align-items-center">
          <span class="task-icon"><i class="far fa-clipboard-list font-size-14"></i></span
          ><span>Task Details</span>
        </p>
      </div>
      <div>
        <div class="d-flex-row task-details-height">
          <div class="d-flex-col-lg-3 d-flex-col-xs-12 d-flex-col border-right">
            <div class="pt-2 pr-2">
              <div class="mb-2">
                <kendo-label
                  text="Task List"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <a (click)="redirectToTaskList()" class="text-primary">{{
                    taskDetails.taskListName
                  }}</a>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Assigned To"
                  class="fw-500 mb-10 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <div *ngFor="let assignee of taskDetails.assignTo">
                    <p>{{ assignee.userName }}</p>
                  </div>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Start Date"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <p>
                    {{
                      taskDetails.isStartDate
                        ? (taskDetails.startDate | date: 'EEE, d MMM yyyy')
                        : '-'
                    }}
                  </p>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Due Date"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <p>
                    {{
                      taskDetails.isDueDate ? (taskDetails.dueDate | date: 'EEE, d MMM yyyy') : '-'
                    }}
                  </p>
                </div>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Created By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails.createdBy)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(taskDetails.creatorId, taskDetails.createdBy)
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar
                  ><span class="ml-5">{{ taskDetails.createdBy }}</span>
                </div>
                <p>{{ taskDetails.createdDate | date: 'EEE, d MMM yyyy' }}</p>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Updated By"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div class="mb-5" *ngIf="taskDetails.updatedBy">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="commonService.getInitials(taskDetails.updatedBy)"
                    [style.backgroundColor]="
                      commonService.generateAvtarColor(taskDetails.creatorId, taskDetails.updatedBy)
                    "
                    class="social-profile-text task-detail-avatar"
                  ></kendo-avatar
                  ><span class="ml-5">{{ taskDetails.updatedBy }}</span>
                </div>
                <ng-container *ngIf="taskDetails.updatedBy; else noUpdateBy">
                  <p>{{ taskDetails.updatedDate | date: 'EEE, d MMM yyyy' }}</p>
                </ng-container>
                <ng-template #noUpdateBy>
                  <p>-</p>
                </ng-template>
              </div>
              <div class="pb-2">
                <kendo-label
                  text="Task ID"
                  class="fw-500 mb-5 display-inline"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <p class="mb-0">{{ taskId }}</p>
              </div>
            </div>
          </div>

          <div class="d-flex-col-lg-9 d-flex-col-xs-12 d-flex-col">
            <div>
              <div class="pt-2">
                <div class="primary-card mb-20">
                  <div class="d-flex justify-content-between flex-wrap gap-15">
                    <div class="d-flex gap-10 align-items-start flex-wrap">
                      <input
                        type="checkbox"
                        kendoCheckBox
                        class="billable-checkbox mr-5"
                        size="large"
                        #notification
                        kendoCheckBox
                      />
                      <div>
                        <div class="d-flex align-items-center mb-15">
                          <ng-container
                            *ngIf="taskDetails.assignTo && taskDetails.assignTo?.length > 0"
                          >
                            <ng-container *ngFor="let user of taskDetails.assignTo">
                              <kendo-avatar
                                rounded="full"
                                size="large"
                                [border]="true"
                                [initials]="commonService.getInitials(user.userName)"
                                [style.backgroundColor]="
                                  commonService.generateAvtarColor(user.userId, user.userName)
                                "
                                class="social-profile-text assignee-profile"
                              ></kendo-avatar>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="taskDetails.assignTo?.length < 0">
                            <i class="fal fa-circle-user font-size-24"></i>
                          </ng-container>
                          <span
                            class="text-primary display-inline clamp w-200 line-height-normal ml-10"
                            title="{{ taskDetails.taskName }}"
                            >{{ taskDetails.taskName }}</span
                          >
                        </div>
                        <div class="d-flex flex-wrap gap-15">
                          <div class="d-flex align-items-center">
                            <span class="font-size-12 mr-5">{{
                              taskDetails.startDate | date: 'd-MMM-yyyy'
                            }}</span>
                            <span>-</span>
                            <span class="font-size-12 due-date ml-5">
                              {{ taskDetails.dueDate | date: 'd-MMM-yyyy' }}
                            </span>
                          </div>
                          <div class="d-flex flex-wrap gap-10">
                            <span class="q-label font-size-12"
                              ><i class="far fa-stopwatch mr-5"></i> Est:
                              {{ taskDetails.estimateHrs }}</span
                            >
                            <span class="q-label font-size-12"
                              ><i class="far fa-clock mr-5"></i> Log: {{ totalLoggedTime }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex gap-15 time-manage-button flex-wrap">
                      <button kendoButton (click)="openTimeLog(taskDetails.taskName)">
                        <i class="fa-regular fa-clock font-size-14 mr-5"></i> Log time
                      </button>
                      <button
                        kendoButton
                        (click)="onStartTimerClick()"
                        fillMode="outline"
                        [disabled]="isTimerRunning"
                      >
                        <i class="far fa-stopwatch font-size-14 mr-5"></i> Start timer
                      </button>
                      <kendo-dropdownbutton
                        [data]="timerActionList"
                        #additionalSetting
                        (itemClick)="onActionItemClicked($event)"
                        ><i class="fas fa-ellipsis-v"></i>
                      </kendo-dropdownbutton>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pb-1 mb-20 border-bottom">
                <kendo-label
                  text="Files"
                  class="mb-5 display-inline font-size-16"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <p class="font-size-12">
                    No files are attached to this task -
                    <a
                      class="font-size-12"
                      href="javascript:void(0)"
                      (click)="openAttachedFile()"
                      class="text-primary"
                      >Attach files to this task</a
                    >
                  </p>
                </div>
              </div>

              <div class="pb-1">
                <kendo-label
                  text="Time Logs"
                  class="mb-20 display-inline font-size-16"
                  labelCssClass="k-form-label"
                ></kendo-label>
                <div>
                  <ng-container *ngIf="timeLogList.length === 0; else gridTemplate">
                    <div class="border-bottom">
                      <p class="font-size-12">
                        No time entries have been logged against this task -
                        <a
                          class="font-size-12"
                          href="javascript:void(0)"
                          (click)="openTimeLog()"
                          class="text-primary"
                          >Log time on this task</a
                        >
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center flex-column mt-4">
                      <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
                      <p class="mt-5">No Time Logged!</p>
                      <p class="text-secondary-text font-size-12">
                        It seems there is no time logged on this task.
                      </p>
                    </div>
                  </ng-container>
                </div>
              </div>
              <ng-template #gridTemplate>
                <div>
                  <div class="d-flex gap-30 primary-card text-primary flex-wrap mb-24">
                    <span class="fw-500"> Filtered Totals: </span>
                    <div>
                      <i class="fa-regular fa-clock"></i>
                      <span class="ml-5 fw-500">logged</span> : {{ totalLoggedTime }}
                    </div>
                    <div>
                      <i class="fa-solid fa-ban"></i>
                      <span class="ml-5 fw-500">Non-billable</span> :
                      {{ totalLoggedNonBillableTime }}
                    </div>
                    <div>
                      <i class="fa-regular fa-circle-check"></i>
                      <span class="ml-5 fw-500">Billable</span> : {{ totalLoggedBillableTime }}
                    </div>
                  </div>
                  <div class="grid-wrapper">
                    <kendo-grid
                      [kendoGridBinding]="timeLogList"
                      [sortable]="true"
                      [sort]="sort"
                      (sortChange)="sortChange($event)"
                      class="timelog-grid"
                    >
                      <kendo-grid-column field="timelogDatetime" title="Date" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'yyyy-MM-dd' }}">{{
                            dataItem.timelogDatetime | date: 'dd-MM-yyyy'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="name"
                        title="Created By"
                        [width]="150"
                        [sortable]="false"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center gap-10">
                            <kendo-avatar
                              rounded="full"
                              size="large"
                              [border]="true"
                              [initials]="commonService.getInitials(taskDetails.createdBy)"
                              [style.backgroundColor]="
                                commonService.generateAvtarColor(
                                  taskDetails.creatorId,
                                  taskDetails.createdBy
                                )
                              "
                              class="social-profile-text"
                            ></kendo-avatar>
                            <span
                              class="clamp"
                              title="{{ getCreatedByName(dataItem.creatorId) }}"
                              >{{ getCreatedByName(dataItem.creatorId) }}</span
                            >
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="timelogDescription"
                        title="Description"
                        [width]="150"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDescription }}">{{
                            dataItem.timelogDescription
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogDatetime" title="Start" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'shortTime' }}">{{
                            dataItem.timelogDatetime | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="end" title="End" [sortable]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{
                            commonService.getEndDate(
                              dataItem.timelogDatetime,
                              dataItem.timelogMinutes
                            ) | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogIsBillable" title="Billable" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <input
                            type="checkbox"
                            kendoCheckBox
                            [checked]="dataItem.timelogIsBillable === 1 ? true : false"
                            class="billable-checkbox grid-checkbox"
                            size="large"
                            disabled
                          />
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogMinutes" title="Time" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogMinutes }}m"
                            >{{ dataItem.timelogMinutes }}m</span
                          >
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        field="hours"
                        title="Hours"
                        [sortable]="false"
                        [width]="80"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.hours }}">{{ dataItem.hours }}</span>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="" title="" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center justify-content-between">
                            <ul class="grid-action-list">
                              <div class="d-flex gap-10">
                                <li>
                                  <a title="Edit" (click)="onEdit(dataItem, taskDetails.taskName)">
                                    <i class="far fa-pen"></i>
                                  </a>
                                </li>
                                <li *ngIf="!dataItem.isDefault">
                                  <a title="Delete" (click)="onDelete(dataItem)">
                                    <i class="far fa-trash-alt"></i>
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                    </kendo-grid>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div kendoDialogContainer></div>
