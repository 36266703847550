import { Environment } from '@abp/ng.core';
export { QMenuComponent, QThemeLibModule } from '@dev/qtheme-lib';

var full = window.location.host;
var parts = full.split('.');
var sub = parts[0];
if (sub == 'www') sub = 'master';
const baseUrl = 'https://{0}.toshaltesting.com';

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'Dash',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://{0}.ids.toshaltesting.com/',
    redirectUri: baseUrl,
    clientId: 'Dash_App',
    responseType: 'code',
    scope: 'offline_access AdministrationService IdentityService SaasService ProjectService TaskService',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://{0}.gateway.toshaltesting.com',
      rootNamespace: 'Toshal.Dash',
    },
  },
} as Environment;
