<kendo-dialog title="{{ headerText }}" (close)="onCloseDialog()" [width]="450">
  <form [formGroup]="peopleForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="People Name" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="name" placeholder="Full Name" #groupName></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Job Title" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="jobTitle" placeholder="Job Title" #groupName></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="company" text="Company" labelCssClass="k-form-label"> </kendo-label>
      <kendo-multiselect
        #company
        [data]="listItems"
        [(ngModel)]="value"
        formControlName="company"
        textField="text"
        valueField="value"
      ></kendo-multiselect>
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="team" text="Team" labelCssClass="k-form-label"> </kendo-label>
      <kendo-multiselect
        #team
        [data]="listItems"
        [(ngModel)]="value"
        formControlName="team"
        textField="text"
        valueField="value"
      ></kendo-multiselect>
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Email" labelCssClass="k-form-label"></kendo-label>
      <kendo-textbox formControlName="email" placeholder="Email" #groupName></kendo-textbox>
    </kendo-formfield>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
    <button kendoButton (click)="onUserSubmit()">{{ submitButtonText }}</button>
  </kendo-dialog-actions>
</kendo-dialog>
