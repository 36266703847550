<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ headerText }}</span>
</kendo-dialog-titlebar>

<div>
  <form [formGroup]="portfolioForm" validateOnSubmit class="k-form k-form-md">
    <kendo-formfield class="mb-20">
      <kendo-label [for]="groupName" text="Portfolio Name" labelCssClass="k-form-label"
        ><span class="required">*</span></kendo-label
      >
      <kendo-textbox formControlName="name" placeholder="Portfolio Name" #groupName></kendo-textbox>
      <kendo-formerror *ngIf="portfolioForm.controls.name.errors?.maxlength"
        >Error: Portfolio Name cannot have more than 5000 character!</kendo-formerror
      >
      <kendo-formerror *ngIf="portfolioForm.controls.name.errors?.required"
        >Error: Portfolio Name is required!</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield class="mb-20">
      <kendo-label [for]="addProject" text="Add Project" labelCssClass="k-form-label">
      </kendo-label>
      <kendo-multiselect
        #addProject
        [clearButton]="false"
        [filterable]="true"
        [checkboxes]="true"
        [data]="listItemData"
        textField="text"
        valueField="value"
        [ngModelOptions]="{ standalone: true }"
        formControlName="addProject"
        (filterChange)="onProjectSearch($event)"
      ></kendo-multiselect>
    </kendo-formfield>
  </form>
</div>
<kendo-dialog-actions>
  <button kendoButton (click)="onCloseDialog()" fillMode="outline">Cancel</button>
  <div [ngClass]="{ 'curser-not-allowd': portfolioForm.invalid }" class="display-inline flex-0">
    <button kendoButton (click)="onPortfolioSubmit()" [disabled]="portfolioForm.invalid">
      {{ submitButtonText }}
    </button>
  </div>
</kendo-dialog-actions>
