import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProjectService } from 'projects/project/src/lib/proxy';
import { TaskType } from 'src/app/enum/task-type';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationMessage } from 'src/app/enum/notification';
export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}
@Component({
  selector: 'app-task-edit-popup',
  templateUrl: './task-edit-popup.component.html',
  styleUrls: ['./task-edit-popup.component.scss'],
})
export class TaskEditPopupComponent {
  taskId: any;
  projectId: any;
  taskForm: FormGroup;
  taskTypeList = [
    {
      id: TaskType.Task,
      name: TaskType[TaskType.Task],
      iconClass: 'far fa-clipboard-list font-size-14',
    },
    {
      id: TaskType.Bug,
      name: TaskType[TaskType.Bug],
      iconClass: 'far fa-bug font-size-14',
    },
  ];
  assigneeList: any[] = [];
  dueDateDefaultValue: Date;
  priorityList: any[] = [
    {
      id: Priority.High,
      text: Priority[Priority.High],
    },
    {
      id: Priority.Low,
      text: Priority[Priority.Low],
    },
    {
      id: Priority.Medium,
      text: Priority[Priority.Medium],
    },
    {
      id: Priority.None,
      text: Priority[Priority.None],
    },
  ];
  taskListId: any;
  taskStatus: any;
  parentTaskId = null;
  constructor(
    public readonly list: ListService,
    public dialogRef: DialogRef,
    private toasterService: ToasterService,
    public commonService: CommonService,
    public projectUser: ProjectUserService,
    public taskService: TaskService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.setForm();
  }

  setDataOnLoad(): void {
    this.getAssigneeList();
  }

  convertMinutesToHoursAndMinutes(totalMinutes: number): { hours: number; minutes: number } {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }

  getTaskDetailById(): void {
    this.spinnerService.show();
    this.taskService.get(this.taskId).subscribe((res: any) => {
      this.spinnerService.hide();
      this.taskListId = res.taskListId;
      this.taskStatus = res.taskStatus;
      this.parentTaskId = res.parentTaskId;
      const { hours, minutes } = this.convertMinutesToHoursAndMinutes(res.estimateMinutes);

      this.taskForm = new FormGroup({
        taskName: new FormControl(res.name, Validators.required),
        assignee: new FormControl(res.users),
        dueDate: new FormControl(res.duedate ? new Date(res.duedate) : ''),
        priority: new FormControl(res.priority),
        taskType: new FormControl(res.taskType),
        estimateHour: new FormControl(hours),
        estimateMinute: new FormControl(minutes),
        description: new FormControl(res.description),
      });
    });
  }

  getAssigneeList(): void {
    const params = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };
    this.projectUser.getPeople(this.projectId, params).subscribe(
      res => {
        if (res.items.length > 0) {
          this.assigneeList = res.items;
        }
        this.getTaskDetailById();
      },
      err => {
        this.getTaskDetailById();
      },
    );
  }

  setForm(): void {
    this.taskForm = new FormGroup({
      taskName: new FormControl('', Validators.required),
      assignee: new FormControl(''),
      dueDate: new FormControl(''),
      priority: new FormControl(''),
      taskType: new FormControl(this.taskTypeList[0].id),
      comments: new FormControl(''),
      estimateHour: new FormControl(''),
      estimateMinute: new FormControl(''),
      description: new FormControl(''),
    });
  }

  onKeyDownForHour(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) ||
      (event.keyCode === 67 && event.ctrlKey === true) ||
      (event.keyCode === 88 && event.ctrlKey === true) ||
      (event.keyCode === 86 && event.ctrlKey === true) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    }
  }

  onKeyDownForMinutes(event: KeyboardEvent) {
    if (
      [46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      (event.keyCode === 65 && event.ctrlKey === true) ||
      (event.keyCode === 67 && event.ctrlKey === true) ||
      (event.keyCode === 88 && event.ctrlKey === true) ||
      (event.keyCode === 86 && event.ctrlKey === true) ||
      (event.keyCode >= 35 && event.keyCode <= 39)
    ) {
      return;
    }
    if (
      (event.shiftKey || event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105)
    ) {
      event.preventDefault();
    } else {
      const currentValue = parseInt((event.target as HTMLInputElement).value + event.key, 10);
      if (currentValue > 59) {
        event.preventDefault();
      }
    }
  }

  onSubmit(): void {
    if (this.taskForm.invalid) {
      this.taskForm.controls.taskName.markAsTouched();
      return;
    }
    const newTaskData: any = this.taskForm.value;
    newTaskData.estimateHour = newTaskData.estimateHour === '' ? 0 : newTaskData.estimateHour;
    newTaskData.estimateMinute = newTaskData.estimateMinute === '' ? 0 : newTaskData.estimateMinute;

    const estimateTime = `${newTaskData.estimateHour}:${newTaskData.estimateMinute}`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);

    const dueDate = new Date(newTaskData.dueDate);
    const param: CreateUpdateTaskDto = {
      isQuickEdit: true,
      id: this.taskId,
      name: newTaskData.taskName,
      progress: 20,
      taskListId: this.taskListId,
      startdate:
        newTaskData.dueDate === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        newTaskData.dueDate === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +newTaskData.priority,
      estimateMinutes: estimateMinutes,
      parentTaskId: this.parentTaskId,
      notify: true,
      assignedToUserIds:
        newTaskData.assignee.length === 0 ? [] : newTaskData.assignee.map(item => item.userId),
      taskType: newTaskData.taskType,
      taskStatus: this.taskStatus,
      description: this.taskForm.controls.description.value,
      subTasks: [],
    };
    this.spinnerService.show();
    this.taskService.update(this.taskId, param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.commonService.onDialogClose(this.dialogRef, true);
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }

  public customStyleContent = `
  .k-content * {
    font-family: 'Poppins', sans-serif;
    color: #404a5f;
  }

  .k-content .k-placeholder {
    font-size: 13px;
    color: #909EB2;
  }`;
}
