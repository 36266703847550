import type { CreateCompanyDto, UpdateCompanyDto } from './companies/models';
import type { CompanyDto, CompanyGetListInput } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  apiName = 'ProjectService';

  checkCompanyNameExists = (companyName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `/api/company/nameExists/${companyName}`,
      },
      { apiName: this.apiName, ...config },
    );

  create = (input: CreateCompanyDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CompanyDto>(
      {
        method: 'POST',
        url: '/api/company',
        body: input.logo,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: '/api/company',
        params: { id },
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CompanyDto>(
      {
        method: 'GET',
        url: '/api/company',
        params: { id },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: CompanyGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CompanyDto>>(
      {
        method: 'GET',
        url: '/api/company/getall',
        params: {
          searchTerm: input.searchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: UpdateCompanyDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CompanyDto>(
      {
        method: 'PUT',
        url: '/api/company',
        params: { id },
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
