import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CreateUpdateProductDto,
  GetProductListDto,
  ProductDto,
} from '../products/dtos/models';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  apiName = 'ProjectService';

  create = (input: CreateUpdateProductDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDto>(
      {
        method: 'POST',
        url: '/api/product',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/product/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDto>(
      {
        method: 'GET',
        url: `/api/product/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAllProductList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDto[]>(
      {
        method: 'GET',
        url: '/api/product/getallproduct',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetProductListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<ProductDto>>(
      {
        method: 'GET',
        url: '/api/product',
        params: {
          name: input.name,
          status: input.status,
          lastVisit: input.lastVisit,
          searchTerm: input.searchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateProductDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ProductDto>(
      {
        method: 'PUT',
        url: `/api/product/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
