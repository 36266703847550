import type {
  CreateUpdatePortfolioDto,
  GetPortfolioListDto,
  PortfolioDto,
} from './portfolios/dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PortfolioService {
  apiName = 'ProjectService';

  create = (input: CreateUpdatePortfolioDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortfolioDto>(
      {
        method: 'POST',
        url: '/api/portfolio',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/portfolio/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortfolioDto>(
      {
        method: 'GET',
        url: `/api/portfolio/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAllPortfolioList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortfolioDto[]>(
      {
        method: 'GET',
        url: '/api/portfolio/getallportfolio',
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetPortfolioListDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PortfolioDto>>(
      {
        method: 'GET',
        url: '/api/portfolio',
        params: {
          name: input.name,
          status: input.status,
          lastVisit: input.lastVisit,
          searchTerm: input.searchTerm,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdatePortfolioDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PortfolioDto>(
      {
        method: 'PUT',
        url: `/api/portfolio/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
