import { Environment } from '@abp/ng.core';
export { QMenuComponent, QThemeLibModule } from '@dev/qtheme-lib';

var full = window.location.host;
var parts = full.split('.');
var sub = parts[0];
if (sub == 'www') sub = 'master';
const baseUrl = 'https://{0}.toshaltesting.com';

export const environment = {
  production: false,
  application: {
    baseUrl,
    name: 'Dash',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'https://keycloak-dev.mysandboxsite.com/realms/' + sub,
    redirectUri: baseUrl,
    clientId: 'Web',
    responseType: 'code',
    scope:
      'offline_access openid profile email phone roles AdministrationService IdentityService SaaSService ProjectService TaskService',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'https://{0}.gateway.toshaltesting.com',
      rootNamespace: 'Toshal.Dash',
    },
  },
} as Environment;
