<kendo-dialog-titlebar (close)="onCloseDialog()">
  <span>{{ headerText }}</span>
</kendo-dialog-titlebar>

<div class="dashboard">
  <kendo-grid [kendoGridBinding]="gridData" [scrollable]="false">
    <ng-template kendoGridNoRecordsTemplate>
      <div *ngIf="this.gridData.length === 0 && isNoRecord">No records available.</div>
    </ng-template>
    <kendo-grid-column field="TaskName" title="TaskName">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div
          class="d-flex align-items-center gap-5 vertical-align-middle"
          *ngIf="!dataItem.hasChildren"
        >
          <input
            type="checkbox"
            kendoCheckBox
            class="billable-checkbox"
            size="large"
            [disabled]="true"
            [ngModel]="dataItem.taskStatus === taskStatusType.Completed"
          />
          <span>
            <i [class]="getTaskTypeIconClass(dataItem.taskType)"></i>
          </span>
          <span class="clamp" title="{{ dataItem.taskName }}">{{ dataItem.taskName }}</span>
        </div></ng-template
      >
    </kendo-grid-column>
    <kendo-grid-column field="Due Date" title="Due Date" [width]="110">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.dueDate }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Project" title="Project" [width]="200">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="d-flex align-items-center gap-5">
          <kendo-avatar
            *ngIf="dataItem.project && !dataItem.projectLogo"
            size="large"
            [border]="true"
            [initials]="commonService.getInitials(dataItem.project)"
            class="project-logo"
          ></kendo-avatar>
          <span class="clamp" title="{{ dataItem.project }}">{{ dataItem.project }}</span>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Task List" title="Task List" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          {{ dataItem.taskListName }}
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="Priority" title="Priority" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="status-container priority-{{ dataItem.priority }}">
          <span class="indicator mr-5"></span>
          {{ dataItem.priority }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>

<kendo-dialog-actions></kendo-dialog-actions>
