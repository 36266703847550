import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FlatBindingDirective, SelectableSettings } from '@progress/kendo-angular-treelist';
import { CategoryDto } from '@project';
import { ToasterService } from '@abp/ng.theme.shared';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ProgressColor } from '@progress/kendo-angular-progressbar';
import { IdentityUserService } from '@abp/ng.identity/proxy';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';
import { NgxSpinnerService } from 'ngx-spinner';
import { TreeItemLookup } from '@progress/kendo-angular-treeview';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import {
  CategoryService,
  CompanyService,
  PortfolioService,
  ProjectService,
} from 'projects/project-service/src/lib/proxy/project-service';
import { ProjectUserService } from '@proxy/project-service/project';
import { ProductService } from '@proxy/project-service/product';
import { Observable, forkJoin, map, of, switchMap } from 'rxjs';
import { TagsService } from 'projects/project-service/src/lib/proxy/project-service/tags/tags.service';
import { ProjectListType } from 'src/app/enum/project-status-filter';

const createProjectGoalFormGroup = dataItem =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    description: new FormControl(dataItem.description, Validators.required),
  });

const createTagFormGroup = dataItem =>
  new FormGroup({
    TagID: new FormControl(dataItem.TagID),
    TagName: new FormControl(dataItem.TagName),
  });

@Component({
  selector: 'lib-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss'],
})
export class AddProjectComponent implements OnInit {
  selectedResponsiblePerson: any;

  assigneeList: any[] = [];
  productList: any[] = [];
  portfolioList: any[] = [];
  assignee: any[] = [];
  projectCategoryList: Array<string> = [];
  productsGoalList: any[] = [];
  tags = [];
  peopleList: any[] = [];
  selectedAssigneeList: any[] = [];

  projectListType: any;
  productId: any;
  portfolioId: any;

  categoryList = { items: [], totalCount: 0 } as PagedResultDto<CategoryDto>;
  companyList = { items: [], totalCount: 0 } as PagedResultDto<CategoryDto>;

  //#region  need to bind it dynamically
  tagsList: any[] = [
    { id: 1, name: 'tags 1' },
    { id: 2, name: 'tags 2' },
    { id: 3, name: 'tags 3' },
    { id: 4, name: 'tags 4' },
  ];

  BudgetRepeatsData: Array<string> = ['Option 1', 'Option 2', 'Option 3'];
  BudgetBasedOnData: Array<string> = ['Option 1', 'Option 2', 'Option 3'];
  connectedPortfolios: Array<string> = ['Option 1', 'Option 2', 'Option 3'];
  //#endregion

  currentTab = 1;
  progressValue = 3;
  selectedValue = 1;
  searchText: string = '';
  editedRowIndex: number;

  isEdit = false;
  toggleTagGrid = false;

  progessLevelIndicatorValue = (this.currentTab / this.progressValue) * 100;
  randomColorList: ProgressColor[] = [
    {
      from: 0,
      to: 25,
      color: '#06D64F',
    },
    {
      from: 25,
      to: 50,
      color: '#06D64F',
    },
    {
      from: 50,
      to: 75,
      color: '#06D64F',
    },
    {
      from: 75,
      to: 100,
      color: '#06D64F',
    },
  ];
  treeListSettings: SelectableSettings = {
    mode: 'row',
    multiple: true,
    drag: false,
  };

  public customStyleContent = `
    .k-content * {
      font-family: 'Poppins', sans-serif;
      color: #404a5f;
    }

    .k-content .k-placeholder {
      font-size: 13px;
      color: #909EB2;
    }`;

  @ViewChild(FlatBindingDirective) dataBinding: FlatBindingDirective;
  @Output() reloadList = new EventEmitter<any>();

  @Input() editProjectValue: any;
  @Input() projectId: any;
  @ViewChild(GridComponent) grid: GridComponent;

  projectForm: FormGroup;
  budgetForm: FormGroup;
  advanceOptionsForm: FormGroup;
  formGroup: FormGroup;
  formTagGroup: FormGroup;
  isAddingTag = false;
  tagName: string = '';
  headerText = 'Create a new';
  peopleSelect = 'Select All';
  tagListData: Array<{ text: string; value: number }>;
  selectedValues: string[] = [];
  tagValue: any = [];
  items: any;
  isTagSaveDisable = false;

  @ViewChild(DropDownListComponent) dropdownList: DropDownListComponent;

  constructor(
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private readonly projectService: ProjectService,
    private categoryService: CategoryService,
    public readonly list: ListService,
    public companyService: CompanyService,
    private dialogRef: DialogRef,
    protected service: IdentityUserService,
    public commonService: CommonService,
    private projectUser: ProjectUserService,
    private spinnerService: NgxSpinnerService,
    private productService: ProductService,
    private portFolioService: PortfolioService,
    private tagsService: TagsService
  ) {
    this.setForm();
    this.getCategoryList();
    this.getCompanyList();
    this.getPeopleList();
    this.getProductList();
    this.getPortfolioList();
    this.getTagsList();
  }

  ngOnInit(): void {
    this.isEdit = false;
    if (this.projectId) {
      this.isEdit = true;
      this.headerText = 'Update';
      this.getAssigneeList();
    }

    if (this.editProjectValue !== undefined && this.editProjectValue !== null) {
      this.isEdit = true;
      this.projectForm.setValue({
        projectTitle: this.editProjectValue.name,
        projectDescription: this.editProjectValue.description,
        company: '',
      });
    }
  }

  getTagsList(): void {
    const param = {
      maxResultCount: null,
    };
    this.tagsService.getAllList(param).subscribe(response => {
      this.items = response.map(tag => ({ text: tag.name, value: tag.id }));
      this.tagListData = this.items.slice();
    });
  }

  getPortfolioList(): void {
    this.portFolioService.getAllPortfolioList().subscribe(res => {
      this.portfolioList = res;
      if (this.projectListType === ProjectListType.Portfolio) {
        const setDefaultPortfolio = this.portfolioList.find(x => x.id === +this.portfolioId);
        this.advanceOptionsForm.controls.portfolio.setValue([setDefaultPortfolio]);
      }
    });
  }

  getProductList(): void {
    this.productService.getAllProductList().subscribe(res => {
      this.productList = res;
      if (this.projectListType === ProjectListType.Product) {
        const setDefaultProduct = this.productList.find(x => x.id === +this.productId);
        this.advanceOptionsForm.controls.product.setValue([setDefaultProduct]);
      }
    });
  }

  getCategoryList(): void {
    const bookStreamCreator = query => this.categoryService.getList(query);
    this.list.hookToQuery(bookStreamCreator).subscribe(response => {
      this.categoryList = response;
    });
  }

  getCompanyList(): void {
    const bookStreamCreator = query => this.companyService.getList(query);
    this.list.hookToQuery(bookStreamCreator).subscribe(response => {
      this.companyList.items = response.items;
    });
  }

  onSearchTextChange(searchText: string): void {
    if (!searchText.trim()) {
      this.searchText = searchText;
      this.getPeopleList();
    }
  }

  getPeopleList(): void {
    const param = {
      filter: this.searchText,
      skipCount: 0,
      maxResultCount: 10,
    };

    this.service.getList(param).subscribe(response => {
      this.peopleList = [];
      if (response.items.length > 0) {
        //#region  need to bind it dynamically
        this.peopleList = [
          {
            id: '5e374752-fc0f-4d53-b5e8-9d7db5c277d8',
            name: 'Toshal Infotech',
            companyId: null,
            avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
            items: [],
          },
        ];
        response.items.forEach(element => {
          const data = {
            id: element.id,
            name: element.userName,
            companyId: '5e374752-fc0f-4d53-b5e8-9d7db5c277d8',
            avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
            items: [],
          };

          this.peopleList[0].items.push(data);
        });
        //#endregion
      }
    });
  }

  setForm(): void {
    this.projectForm = this.fb.group({
      projectTitle: ['', [Validators.required, Validators.maxLength(255)]],
      company: ['', Validators.required],
      projectDescription: [''],
    });

    this.advanceOptionsForm = this.fb.group({
      projectCategory: [null],
      time: [false],
      product: [[]],
      portfolio: [[]],
      tagName: [''],
    });

    this.budgetForm = this.fb.group({
      budgetAmount: new FormControl(),
      startDate: new FormControl(new Date()),
      endDate: new FormControl(new Date()),
      acceptNews: new FormControl(),
      budgetBasedOn: new FormControl(),
      budgetRepeats: new FormControl(),
    });
  }

  getAssigneeList(): void {
    // this.projectService.get
    const params = {
      sorting: '',
      skipCount: 0,
      maxResultCount: 1000,
    };

    this.projectUser
      .getPeople(this.projectId, params)
      .pipe(
        switchMap(res => {
          if (res.items.length === 0) {
            return of([]);
          }

          const userObservables = res.items.map(element =>
            this.commonService.getUserObservables([element]).pipe(
              map(updatedUsers => {
                const user = updatedUsers[0];
                return {
                  id: user.userId,
                  name: user.userName,
                  companyId: '5e374752-fc0f-4d53-b5e8-9d7db5c277d8',
                  avatar: 'https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg',
                  items: [],
                };
              })
            )
          );

          return forkJoin(userObservables);
        })
      )
      .subscribe(assigneeList => {
        this.assigneeList = assigneeList;
        this.selectedAssigneeList = this.assigneeList.map(x => ({ itemKey: x.name }));
        this.spinnerService.show();
        setTimeout(() => {
          this.editProjectData(this.projectId);
        }, 1500);
      });
  }

  editProjectData(projectId): void {
    this.projectService.get(projectId).subscribe((res: any) => {
      let productDataList: any[] = this.productList.filter(product =>
        res.productIds.includes(product.id)
      );

      let portfolioDataList: any[] = this.portfolioList.filter(portfolio =>
        res.portfolioIds.includes(portfolio.id)
      );
      this.projectForm.setValue({
        projectTitle: res.name,
        projectDescription: res.description,
        company: res.companyId,
      });
      this.advanceOptionsForm.setValue({
        projectCategory: res.categoryId,
        time: res.isBillable,
        product: productDataList,
        portfolio: portfolioDataList,
        tagName: '',
      });
      this.selectedResponsiblePerson = res.responsiblePersonId;
      this.productsGoalList = [];
      if (res.goals.length > 0) {
        res.goals.forEach(element => {
          const param = {
            id: element.id,
            description: element.description,
          };
          this.productsGoalList.push(param);
        });
      }

      this.tags = [];
      if (res.tagIds.length > 0) {
        res.tagIds.forEach(element => {
          const item = this.tagListData.find(item => item.value === element);
          if (item) {
            const param = {
              tagId: element,
              name: item.text,
            };
            this.tags.push(param);
          }
        });
      }

      this.spinnerService.hide();
    });
  }

  //#region  stepper
  nextStepClick(): void {
    this.projectForm.markAllAsTouched();
    this.currentTab = this.currentTab + 1;
    this.progessLevelIndicatorValue = (this.currentTab / this.progressValue) * 100;
  }

  previousStepClick(): void {
    this.currentTab = this.currentTab - 1;
    this.progessLevelIndicatorValue = (this.currentTab / this.progressValue) * 100;
  }
  //#endregion

  onPeopleSelectionChange(event: any): void {
    if (event.action === 'add') {
      this.peopleSelect = 'DeSelect All';
      event.items.forEach(element => {
        if (element.dataItem.items.length === 0) {
          this.assigneeList.push(element.dataItem);
        }
      });
    } else if (event.action === 'remove') {
      this.peopleSelect = 'Select All';
      event.items.forEach(element => {
        this.assigneeList = this.assigneeList.filter(x => x.id !== element.dataItem.id);
      });
      this.dropdownList.reset();
    }

    if (this.assigneeList.length === 0) {
      this.selectedAssigneeList = [];
      this.selectedResponsiblePerson = null;
      this.peopleSelect = 'Select All';
    }
  }

  createProject() {
    this.spinnerService.show();
    const formData = {
      ...this.projectForm.value,
      advanceOptionsFormData: this.advanceOptionsForm.value,
    };

    const productIds = formData.advanceOptionsFormData.product
      .map(obj => obj.id)
      .filter(Boolean)
      .flat();

    const portfolioIds = formData.advanceOptionsFormData.portfolio
      .map(obj => obj.id)
      .filter(Boolean)
      .flat();

    const param = {
      name: formData.projectTitle,
      companyId: formData.company,
      description: formData.projectDescription,
      categoryId: formData.advanceOptionsFormData.projectCategory,
      isBillable: formData.advanceOptionsFormData.time,
      users: this.assigneeList.map(assignee => assignee.id),
      goals: this.productsGoalList.length > 0 ? this.productsGoalList : [],
      responsiblePersonId: this.selectedResponsiblePerson,
      productIds: productIds,
      portfolioIds: portfolioIds,
      tags: this.tags.length > 0 ? this.tags : [],
    };

    if (this.projectId) {
      this.projectService.update(this.projectId, param).subscribe(
        res => {
          this.spinnerService.hide();
          this.toasterService.success(
            NotificationMessage.projectUpdatedMessage,
            '',
            this.commonService.toasterMessageConfiguration
          );
          this.commonService.onDialogClose(this.dialogRef, true);
        },
        err => {
          this.spinnerService.hide();
        }
      );
    } else {
      this.projectService.checkProjectName(param.name).subscribe(isNotExist => {
        if (isNotExist) {
          this.projectService.create(param).subscribe(
            () => {
              this.spinnerService.hide();
              this.toasterService.success(
                NotificationMessage.projectAddedMessage,
                '',
                this.commonService.toasterMessageConfiguration
              );
              this.commonService.onDialogClose(this.dialogRef, true);
            },
            err => {
              this.spinnerService.hide();
            }
          );
        } else {
          this.projectForm.controls.projectTitle.setErrors({ incorrect: true });
          this.spinnerService.hide();
          return this.toasterService.error(
            NotificationTextMessage.projectNameAlreadyExistMessage,
            '',
            this.commonService.toasterMessageConfiguration
          );
        }
      });
    }
  }

  onClose(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  //#region Project Goal Grid
  onAddProjectGoal({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = createProjectGoalFormGroup({
      id: 0,
      description: '',
      UnitPrice: 0,
      UnitsInStock: '',
      CategoryID: 1,
    });

    sender.addRow(this.formGroup);
  }

  onEditProjectGoal({ sender, rowIndex, dataItem }: EditEvent): void {
    this.closeEditor(sender);
    this.formGroup = createProjectGoalFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  onCancelProjectGoal({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }

  onSaveProjectGoal({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    const product = formGroup.value;
    if (rowIndex === -1) {
      this.productsGoalList.push(product);
    } else {
      this.productsGoalList[rowIndex] = product;
    }
    sender.closeRow(rowIndex);
  }

  closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  onRemoveProjectGoal({ dataItem, rowIndex }: RemoveEvent) {
    this.productsGoalList.splice(rowIndex, 1);
  }
  //#endregion

  //#region Tag Grid
  closeTagEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formTagGroup = undefined;
  }

  addTagHandler({ sender }: AddEvent): void {
    this.closeTagEditor(sender);

    this.formTagGroup = createTagFormGroup({
      TagName: '',
    });
    this.isAddingTag = true;
    sender.addRow(this.formTagGroup);
  }

  addNewTag(selectedValue: any) {
    const param = {
      tagId: selectedValue.value,
      name: selectedValue.text,
    };
    this.tags.push(param);
    this.isAddingTag = false;
    this.tagValue = [];
  }

  removeTag(name) {
    this.tags = this.tags.filter(item => item.name !== name);
  }

  onCancelTag(): void {
    this.tagValue = [];
    this.isAddingTag = false;
  }

  tagFilter(value) {
    this.tagListData = this.items.filter(
      s => s.text && s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  filterTagListData(): any[] {
    return this.tagListData.filter(item => !this.tags.some(tag => tag.name === item.text));
  }

  valueChange(value: any): void {
    this.isTagSaveDisable = value === undefined ? false : true;
  }

  selectionChange(value: any): void {
    this.isTagSaveDisable = value === undefined ? false : true;
  }

  valueNormalizer = (text: Observable<string>) =>
    text.pipe(
      map((text: string) => {
        return {
          value: 0,
          text: text,
        };
      })
    );
}
