import {
  AuthService,
  ListService,
  PagedAndSortedResultRequestDto,
  PagedResultDto,
} from '@abp/ng.core';
import { RolesComponent, eIdentityComponents } from '@abp/ng.identity';
import { IdentityRoleDto, IdentityRoleService } from '@abp/ng.identity/proxy';
import { ePermissionManagementComponents } from '@abp/ng.permission-management';
import { ConfirmationService } from '@abp/ng.theme.shared';
import {
  EXTENSIONS_IDENTIFIER,
  FormPropData,
  generateFormFromProps,
} from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CommonService } from 'src/core/services';
import swal from 'sweetalert/dist/sweetalert.min.js';

@Component({
  selector: 'app-custom-roles',
  templateUrl: './custom-roles.component.html',
  styleUrls: ['./custom-roles.component.scss'],
  providers: [
    ListService,
    {
      provide: EXTENSIONS_IDENTIFIER,
      useValue: eIdentityComponents.Roles,
    },
    {
      provide: RolesComponent,
      useExisting: CustomRolesComponent,
    },
  ],
})
export class CustomRolesComponent implements OnInit, OnDestroy {
  data: PagedResultDto<IdentityRoleDto> = { items: [], totalCount: 0 };

  form: FormGroup;

  selected: IdentityRoleDto;

  isModalVisible: boolean;

  visiblePermissions = false;
  modalBusy = false;
  isDarkTheme = false;
  gridHeight: number;

  providerKey: string;
  entityDisplayName: string;
  permissionManagementKey = ePermissionManagementComponents.PermissionManagement;

  onVisiblePermissionChange = event => {
    this.visiblePermissions = event;
    if (!this.visiblePermissions) {
      this.isAddEditPermissionOpened = false;
    }
  };
  isAddEditOpened = false;
  isAddEditPermissionOpened = false;
  @ViewChild('dialog') dialog: any;

  @ViewChild('dialogPermission') dialogPermission: any;
  editActionSubscription: Subscription;
  deletActionSubscription: Subscription;
  permissionActionSubscription: Subscription;
  searchText = '';

  constructor(
    public readonly list: ListService<PagedAndSortedResultRequestDto>,
    protected confirmationService: ConfirmationService,
    protected injector: Injector,
    protected service: IdentityRoleService,
    private commonService: CommonService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  open(): void {
    this.isAddEditOpened = true;
  }

  public close(status: string): void {
    this.isAddEditOpened = false;
  }

  closePermission(status: string): void {
    this.isAddEditPermissionOpened = false;
  }

  ngOnInit(): void {
    this.hookToQuery();
    this.isDarkTheme = localStorage.getItem('currentSelectedTheme') === 'dark';
    this.commonService.themeColorChange.subscribe(newTheme => {
      this.isDarkTheme = newTheme === 'dark';
    });

    this.editActionSubscription = this.commonService.editChange.subscribe(data => {
      this.edit(data);
    });
    this.permissionActionSubscription = this.commonService.permissionChange.subscribe(data => {
      this.openPermissionsModal(data);
    });
    this.deletActionSubscription = this.commonService.deleteChange.subscribe(data => {
      this.delete(data);
    });
  }

  ngOnDestroy(): void {
    this.editActionSubscription?.unsubscribe();
    this.deletActionSubscription?.unsubscribe();
    this.permissionActionSubscription?.unsubscribe();
  }

  buildForm(): void {
    const data = new FormPropData(this.injector, this.selected);
    this.form = generateFormFromProps(data);
  }

  openModal(): void {
    this.buildForm();
    this.isModalVisible = true;
    this.open();
    this.commonService.astricValidationColor(this.cdr, this.elementRef, this.renderer);
  }

  add(): void {
    this.selected = {} as IdentityRoleDto;
    this.openModal();
  }

  edit(id: string): void {
    this.service.get(id).subscribe(res => {
      this.selected = res;
      this.openModal();
    });
  }

  save(): void {
    if (!this.form.valid) return;
    this.modalBusy = true;

    const { id } = this.selected;
    (id
      ? this.service.update(id, { ...this.selected, ...this.form.value })
      : this.service.create(this.form.value)
    )
      .pipe(finalize(() => (this.modalBusy = false)))
      .subscribe(() => {
        this.isModalVisible = false;
        this.isAddEditOpened = false;
        this.list.get();
      });
  }

  delete(data: any): void {
    swal({
      title: 'Are you sure?',
      text: `Role ${data.name} will be deleted. Do you confirm that?`,
      icon: 'warning',
      buttons: {
        cancel: 'Cancel',
        confirm: 'Yes',
      },
      reverseButtons: true,
    }).then((willDelete: boolean) => {
      if (willDelete) {
        this.service.delete(data.id).subscribe(() => this.list.get());
      }
    });
  }

  private hookToQuery(): void {
    this.list.hookToQuery(query => this.service.getList(query)).subscribe(res => (this.data = res));
  }

  openPermissionsModal(data: any): void {
    this.providerKey = data.name;
    this.entityDisplayName = data.name;
    setTimeout(() => {
      this.visiblePermissions = true;
      this.isAddEditPermissionOpened = true;
    }, 0);
  }

  sort(data): void {
    const { prop, dir } = data.sorts[0];
    this.list.sortKey = prop;
    this.list.sortOrder = dir;
  }

  onSearch(event: any): void {
    if (event.keyCode === 13) {
      this.list.filter = this.searchText;
      this.list.get();
    }
  }
}
