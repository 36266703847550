import type { DashboardCardModel } from './dtos/models';
import type { DashBoardPopupTaskListModel, DashBoardTaskModel } from './models';
import type { TaskTypeStatus } from './task-type-status.enum';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  apiName = 'TaskService';

  getDashboardCard = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashboardCardModel>(
      {
        method: 'GET',
        url: '/api/dashboard/dashboardcard',
      },
      { apiName: this.apiName, ...config },
    );

  getDashboardTaskListByStatus = (taskTypeStatus: TaskTypeStatus, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashBoardPopupTaskListModel[]>(
      {
        method: 'GET',
        url: '/api/dashboard/dashboardtasklistbystatus',
        params: { taskTypeStatus },
      },
      { apiName: this.apiName, ...config },
    );

  getDueTodayTaskList = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DashBoardTaskModel>(
      {
        method: 'GET',
        url: '/api/dashboard/dashboardtasklist',
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
