import { Component } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { CommonService } from 'src/core/services';
import {
  CategoryDto,
  CategoryService,
} from 'projects/project-service/src/lib/proxy/project-service';

@Component({
  selector: 'app-project-category',
  templateUrl: './project-category.component.html',
  styleUrls: ['./project-category.component.scss'],
})
export class ProjectCategoryComponent {
  public customerList: any[] = [
    {
      text: 'All Projects',
      items: [],
    },
    {
      text: 'Projects',
      items: [{ text: 'Bed Linen' }, { text: 'Curtains & Blinds' }, { text: 'Carpets' }],
    },
    {
      text: 'Q Projects',
      items: [],
    },
  ];
  categoryData = { items: [], totalCount: 0 } as PagedResultDto<CategoryDto>;

  constructor(
    public dialogRef: DialogRef,
    private dialogService: DialogService,
    private categoryService: CategoryService,
    public readonly list: ListService,
    public commonService: CommonService,
  ) {}

  ngOnInit(): void {
    this.getCategoryList();
  }

  getCategoryList(): void {
    const bookStreamCreator = query => this.categoryService.getList(query);
    this.list.hookToQuery(bookStreamCreator).subscribe(response => {
      this.categoryData = response;
    });
  }
  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }

  public onAddCategory(): void {
    const dialogRef = this.dialogService.open({
      content: AddCategoryComponent,
      width: 450,
    });
  }
}
