import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TaskType } from 'src/app/enum/task-type';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';
import { TaskService } from 'projects/task-service/src/lib/proxy/task-service/task/task.service';
import {
  CreateUpdateTaskDto,
  TaskStatusType,
} from 'projects/task-service/src/lib/proxy/task-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TimelogService } from 'projects/task-service/src/lib/proxy/task-service/timelog/timelog.service';
import { TimeLogPopupComponent } from '../../time-log-popup/time-log-popup.component';
import { GetIdentityUsersInput, IdentityUserService } from '@abp/ng.identity/proxy';
import { ListService } from '@abp/ng.core';
import { NotificationMessage, NotificationTextMessage } from 'src/app/enum/notification';
import { ToasterService } from '@abp/ng.theme.shared';
import swal from 'sweetalert/dist/sweetalert.min.js';
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  RemoveEvent,
  SaveEvent,
} from '@progress/kendo-angular-grid';
import { AttachTaskFilePopupComponent } from '../../attach-task-file-popup/attach-task-file-popup.component';

const createSubTaskFormGroup = dataItem =>
  new FormGroup({
    id: new FormControl(dataItem.id),
    taskName: new FormControl(dataItem.taskName, Validators.required),
    assignee: new FormControl(dataItem.assignee),
    dueDate: new FormControl(dataItem.dueDate),
    priority: new FormControl(dataItem.priority),
    comments: new FormControl('1'),
    taskId: new FormControl(dataItem.taskId),
    hasChildren: new FormControl(!!dataItem.subTaskIds),
    taskType: new FormControl(dataItem.taskType),
    taskStatus: new FormControl(dataItem.taskStatus),
    estimateMinutes: new FormControl(dataItem.estimateMinutes),
    isAllSubTaskCompleted: new FormControl(dataItem.isAllSubTaskCompleted),
  });

export class TaskDateDto {
  year: number;
  month: number;
  day: number;

  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
}

@Component({
  selector: 'app-add-task-more-details',
  templateUrl: './add-task-more-details.component.html',
  styleUrls: ['./add-task-more-details.component.scss'],
})
export class AddTaskMoreDetailsComponent implements OnInit {
  popupClass = 'task-type-dropdown';
  taskTypeIconClass: string = '';
  taskListId = 0;

  @Input() taskId = 0;
  @Input() assigneeList = [];
  @Input() projectId = 0;
  @Input() sortingName = '';

  @Output()
  readonly triggerIsToggle = new EventEmitter<any>();

  subtaskList: any[] = [];
  timeLogList: any[] = [];
  userList: any[];

  taskStatusType = TaskStatusType;
  priorityEnum = Priority;

  isRender = false;
  isShowAssigneeMultiSelect = false;
  isShowDueDateInput = false;
  isShowPriorityInput = false;
  isAllSubTaskCompleted = false;
  isDrawerOpened: boolean = true;

  totalLoggedTime: string;
  totalLoggedNonBillableTime: string;
  totalLoggedBillableTime: string;

  sorting = null;

  editedRowIndex: number;
  startTime: Date;
  timerInterval: any;
  startDate: any;
  dueDate: any;
  endTime: Date;
  createdBy: any;
  creatorId: any;

  formGroup: FormGroup;
  taskForm: FormGroup;

  constructor(
    public commonService: CommonService,
    public taskService: TaskService,
    private spinnerService: NgxSpinnerService,
    private timelogService: TimelogService,
    private dialogService: DialogService,
    protected service: IdentityUserService,
    public readonly list: ListService<GetIdentityUsersInput>,
    private toasterService: ToasterService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  onAddSubTask({ sender }: AddEvent): void {
    this.closeEditor(sender);

    this.formGroup = createSubTaskFormGroup({
      id: 0,
      taskName: '',
      assignee: '',
      dueDate: '',
      priority: Priority.Low,
      comments: '1',
      taskId: this.taskId,
      hasChildren: false,
      taskType: this.taskTypeList[0].id,
      taskStatus: TaskStatusType.New,
      estimateMinutes: 0,
      isAllSubTaskCompleted: true,
    });

    sender.addRow(this.formGroup);
  }

  onCancelSubTask({ sender, rowIndex }: CancelEvent): void {
    this.closeEditor(sender, rowIndex);
  }

  checkIsSubDisable(dataItem: any): boolean {
    if (dataItem.hasChildren) {
      return !dataItem.isAllSubTaskCompleted;
    }
    return false;
  }

  onSaveSubTask({ sender, rowIndex, formGroup, isNew }: SaveEvent): void {
    let newTaskData = formGroup.value;

    const dueDate = new Date(newTaskData.dueDate);

    const param1 = {
      id: newTaskData.id,
      taskName: newTaskData.taskName,
      assignee: newTaskData.assignee,
      dueDate: newTaskData.dueDate,
      priority: newTaskData.priority,
      comments: '1',
      taskId: this.taskId,
      hasChildren: newTaskData.hasChildren,
      taskType: newTaskData.taskType,
      taskStatus: isNew ? TaskStatusType.New : newTaskData.taskStatus,
      estimateMinutes: newTaskData.estimateMinutes,
      isAllSubTaskCompleted: false,
    };

    if (isNew) {
      this.subtaskList.push(param1);
    } else {
      this.subtaskList[rowIndex] = param1;
    }

    this.isAllSubTaskCompleted = this.checkIsDisable();
    sender.closeRow(rowIndex);
  }

  closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  onRemoveSubTask({ dataItem }: RemoveEvent) {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + dataItem.taskName + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.spinnerService.show();

        this.taskService.delete(dataItem.id).subscribe(res => {
          this.spinnerService.hide();
          this.getSubtask();
        });
      }
    });
  }

  onEditSubTask({ sender, rowIndex, dataItem }: EditEvent): void {
    this.closeEditor(sender);
    this.formGroup = createSubTaskFormGroup(dataItem);
    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  setTaskTypeValue(event): any {
    this.taskForm.controls.taskType.setValue(event.id);
  }

  checkIsDisable(): boolean {
    if (this.subtaskList.length > 0) {
      return !this.subtaskList.every(subtask => subtask.taskStatus === TaskStatusType.Completed);
    }
    return false;
  }

  getInitials(assigneeList: any): any {
    if (assigneeList) {
      return {
        avatar: this.commonService.getInitials(assigneeList.userName),
        generateAvtarColor: this.commonService.generateAvtarColor(
          assigneeList.userId,
          assigneeList.userName,
        ),
      };
    }
    return '';
  }

  getCreatedBy(creatorId): any {
    this.service.getList(creatorId).subscribe(res => {
      if (res.items && res.items.length > 0) {
        const foundItem = res.items.find(item => creatorId === item.id);
        if (foundItem) {
          this.createdBy = foundItem.name;
          return foundItem.name;
        } else {
          return null;
        }
      } else {
        return null;
      }
    });
  }

  getSubtask(): void {
    this.taskService
      .getSubTasksByParentTaskIdAndSorting(this.taskId, this.sortingName)
      .subscribe(x => {
        this.spinnerService.show();
        this.subtaskList = x.items.map(element => ({
          id: element.id,
          taskName: element.name,
          assignee: element.users,
          dueDate: element.duedate,
          priority: element.priority,
          comments: '1',
          taskId: this.taskId,
          hasChildren: !!element.subTaskIds,
          taskType: element.taskType,
          taskStatus: element.taskStatus,
          estimateMinutes: element.estimateMinutes,
          isAllSubTaskCompleted: element.isAllSubTaskCompleted,
        }));
        this.isAllSubTaskCompleted = this.checkIsDisable();
        this.spinnerService.hide();
      });
  }

  getSubTaskDataById(data: any): void {
    this.taskId = data.id;
    this.setForm();
  }

  getDataById(): void {
    this.spinnerService.show();
    this.taskService.get(this.taskId).subscribe(res => {
      this.taskListId = res.taskListId;
      this.spinnerService.hide();
      this.createdBy = this.getCreatedBy(res.creatorId);
      this.creatorId = res.creatorId;
      this.getAllTimeLogList();
      this.getUserList();

      const { hours, minutes } = this.commonService.convertMinutesToHoursAndMinutes(
        res.estimateMinutes,
      );

      this.getSubtask();
      this.taskForm.patchValue({
        taskStatus: res.taskStatus,
        taskName: res.name,
        taskType: res.taskType,
        estimateMinutes: res.estimateMinutes,
        description: res.description,
        assignee: res.users,
        dueDate: res.duedate ? new Date(res.duedate) : '',
        priority: res.priority,
        hasChildren: !!res.subTaskIds,
        isAllSubTaskCompleted: res.isAllSubTaskCompleted,
        taskListId: res.taskListId,
        parentTaskId: res.parentTaskId,
        estimateHour: hours,
        estimateMinute: minutes,
      });

      this.isRender = true;
    });
  }

  setForm(): void {
    this.taskForm = new FormGroup({
      taskListId: new FormControl(''),
      parentTaskId: new FormControl(null),
      taskStatus: new FormControl(''),
      taskName: new FormControl('', [Validators.required]),
      taskType: new FormControl(this.taskTypeList[0].id),
      description: new FormControl(''),
      assignee: new FormControl(null),
      dueDate: new FormControl(''),
      priority: new FormControl(''),
      estimateMinutes: new FormControl(''),
      hasChildren: new FormControl(false),
      isAllSubTaskCompleted: new FormControl(false),
      estimateHour: new FormControl(''),
      estimateMinute: new FormControl(''),
    });
    this.getDataById();
  }

  taskTypeList = [
    {
      id: TaskType.Task,
      name: TaskType[TaskType.Task],
      iconClass: 'far fa-clipboard-list font-size-14',
    },
    {
      id: TaskType.Bug,
      name: TaskType[TaskType.Bug],
      iconClass: 'far fa-bug font-size-14 bug-icon-color',
    },
  ];

  getIconClass(taskType: number): string {
    return this.taskTypeList.find(x => x.id === taskType).iconClass;
  }

  priorityList: any[] = [
    {
      id: Priority.High,
      text: Priority[Priority.High],
    },
    {
      id: Priority.Low,
      text: Priority[Priority.Low],
    },
    {
      id: Priority.Medium,
      text: Priority[Priority.Medium],
    },
    {
      id: Priority.None,
      text: Priority[Priority.None],
    },
  ];

  public customStyleContent = `
    .k-content * {
      font-family: 'Poppins', sans-serif;
      color: #404a5f;
    }

    .k-content .k-placeholder {
      font-size: 13px;
      color: #909EB2;
    }`;

  sortChange(sort): void {
    this.sorting = sort[0].field + ' ' + (sort[0].dir ?? '');
    this.getAllTimeLogList();
  }

  removeTrailingZeros(str: string): string {
    return str.replace(/(\.0+|(?<=\..*?)0+)$/, '');
  }

  openTimeLog(taskName) {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });
    this.startTime = new Date();
    this.endTime = new Date();
    dialogRef.content.instance.startTime = this.startTime;
    dialogRef.content.instance.endTime = this.endTime;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = taskName;
    dialogRef.content.instance.createdBy = this.createdBy;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.content.instance.creatorId = this.creatorId;
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getAllTimeLogList();
      }
    });
  }

  calculateTotalTime(logs: any[]): number {
    return logs.reduce((total, log) => total + log.timelogMinutes, 0);
  }

  getAllTimeLogList(): void {
    this.spinnerService.show();

    const params = {
      userId: null,
      taskId: this.taskId,
      projectId: null,
      timelogDatetime: null,
      timelogMinutes: null,
      timelogDescription: null,
      timelogIsBillable: null,
      timelogHasStartTime: null,
      taskIdPreMove: null,
      deskTicketId: null,
      timelogInvoiceNumber: null,
      projectBillingInvoiceId: null,
      timelogFinalCost: null,
      maxResultCount: null,
      sorting: this.sorting,
    };
    this.timelogService.getList(params).subscribe(res => {
      this.spinnerService.hide();
      this.timeLogList = [];
      if (res.items.length > 0) {
        res.items.forEach((element: any) => {
          element.hours = this.removeTrailingZeros((element.timelogMinutes / 60).toFixed(3));
          if (element.taskId === this.taskId) {
            this.timeLogList.push(element);
          }
        });

        const billableLogs = this.timeLogList.filter(log => log.timelogIsBillable === 1);
        const nonBillableLogs = this.timeLogList.filter(log => log.timelogIsBillable !== 1);

        this.totalLoggedTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(this.timeLogList),
        );
        this.totalLoggedBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(billableLogs),
        );
        this.totalLoggedNonBillableTime = this.commonService.getHoursAndMinutesFromTotalMinutes(
          this.calculateTotalTime(nonBillableLogs),
        );
      }
    });
  }

  getUserList() {
    this.list
      .hookToQuery(query => this.service.getList(query))
      .subscribe(res => {
        this.userList = res.items;
      });
  }

  getCreatedByName(createdById) {
    if (!this.userList) {
      return null;
    }
    const foundItem = this.userList.find(item => createdById === item.id);
    if (foundItem) {
      return foundItem.name;
    } else {
      return null;
    }
  }

  onSubTaskCompleteChange(data: any, index: any): void {
    if (data.taskStatus === TaskStatusType.New || data.taskStatus === TaskStatusType.ReOpened) {
      this.subtaskList[index].taskStatus = TaskStatusType.Completed;
    } else if (data.taskStatus === TaskStatusType.Completed) {
      this.subtaskList[index].taskStatus = TaskStatusType.New;
    }

    this.isAllSubTaskCompleted = this.checkIsDisable();
  }

  onTaskCompleteChange(): void {
    if (
      this.taskForm.controls.taskStatus.value === TaskStatusType.New ||
      this.taskForm.controls.taskStatus.value === TaskStatusType.ReOpened
    ) {
      this.taskService.markTaskAsCompleteById(this.taskId).subscribe(res => {
        this.taskForm.controls.taskStatus.setValue(TaskStatusType.Completed);
      });
    } else if (this.taskForm.controls.taskStatus.value === TaskStatusType.Completed) {
      this.taskService.markTaskAsReopenedById(this.taskId).subscribe(res => {
        this.taskForm.controls.taskStatus.setValue(TaskStatusType.ReOpened);
      });
    }
  }

  onSave(): void {
    let subTask: any[] = [];

    this.subtaskList.forEach(element => {
      const dueDateElement = new Date(element.dueDate);
      const data: CreateUpdateTaskDto = {
        isQuickEdit: true,
        id: element.id,
        name: element.taskName,
        description: '',
        progress: 20,
        taskListId: this.taskListId,
        startdate:
          element.dueDate === null || element.dueDate === undefined || element.dueDate === ''
            ? null
            : new TaskDateDto(
                dueDateElement.getFullYear(),
                dueDateElement.getMonth() + 1,
                dueDateElement.getDate(),
              ),
        duedate:
          element.dueDate === null || element.dueDate === undefined || element.dueDate === ''
            ? null
            : new TaskDateDto(
                dueDateElement.getFullYear(),
                dueDateElement.getMonth() + 1,
                dueDateElement.getDate(),
              ),
        priority: +element.priority,
        estimateMinutes: element.estimateMinutes,
        parentTaskId: this.taskId,
        notify: true,
        assignedToUserIds:
          element.assignee === null || element.assignee === undefined || element.assignee === ''
            ? []
            : element.assignee,
        taskType: element.taskType,
        taskStatus: element.taskStatus,
        subTasks: [],
      };
      subTask.push(data);
    });

    const dueDate = new Date(this.taskForm.controls.dueDate.value);
    const estimateTime = `${
      this.taskForm.value.estimateHour === '' ? 0 : this.taskForm.value.estimateHour
    }:${this.taskForm.value.estimateMinute === '' ? 0 : this.taskForm.value.estimateMinute}`;

    const estimateMinutes = this.commonService.getTotalMinutesFromHoursAndMinutes(estimateTime);
    const param: CreateUpdateTaskDto = {
      isQuickEdit: false,
      id: this.taskId,
      name: this.taskForm.controls.taskName.value,
      description: this.taskForm.controls.description.value,
      progress: 20,
      startdate:
        this.taskForm.controls.dueDate.value === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      duedate:
        this.taskForm.controls.dueDate.value === ''
          ? null
          : new TaskDateDto(dueDate.getFullYear(), dueDate.getMonth() + 1, dueDate.getDate()),
      priority: +this.taskForm.controls.priority.value,
      estimateMinutes: estimateMinutes,
      taskListId: this.taskForm.controls.taskListId.value,
      parentTaskId: this.taskForm.controls.parentTaskId.value,
      notify: true,
      assignedToUserIds:
        this.taskForm.controls.assignee.value.length > 0
          ? this.taskForm.controls.assignee.value.map(item => item.userId)
          : [],
      taskType: this.taskForm.controls.taskType.value,
      taskStatus: this.taskForm.controls.taskStatus.value,
      subTasks: subTask,
    };
    this.taskService.update(this.taskId, param).subscribe(
      res => {
        this.spinnerService.hide();
        this.toasterService.success(
          NotificationMessage.updateTaskSuccessMsg,
          '',
          this.commonService.toasterMessageConfiguration,
        );
        this.triggerIsToggle.emit(true);
        document.body.style.overflow = 'scroll';
      },
      err => {
        this.spinnerService.hide();
      },
    );
  }

  onEdit(data): void {
    const dialogRef = this.dialogService.open({
      content: TimeLogPopupComponent,
      width: 500,
      cssClass: 'add-time-log-quickly',
    });
    dialogRef.content.instance.timeLogId = data.id;
    dialogRef.content.instance.taskName = this.taskForm.controls.taskName.value;
    dialogRef.content.instance.createdBy = this.createdBy;
    dialogRef.content.instance.creatorId = this.creatorId;
    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.projectId = this.projectId;
    dialogRef.result.subscribe((res: any) => {
      if (res && res.confirmed) {
        this.getAllTimeLogList();
      }
    });
  }

  onDelete(data: any): void {
    swal({
      title: NotificationTextMessage.areYouSureMessage,
      text: NotificationTextMessage.deleteMessageHeader + data.timelogDescription + ' ?',
      icon: 'warning',
      buttons: {
        cancel: {
          text: 'Cancel',
          visible: true,
          closeModal: true,
        },
        confirm: {
          text: 'Yes',
        },
      },
      dangerMode: true,
    }).then(confirmed => {
      if (confirmed) {
        this.timelogService.delete(data.id).subscribe(
          res => {
            this.toasterService.success(
              NotificationMessage.deleteTimelogtSuccessMsg,
              '',
              this.commonService.toasterMessageConfiguration,
            );
            this.getAllTimeLogList();
          },
          error => {
            const errorMessage = error.error.error.message || 'Delete Failed';
            this.toasterService.error(
              errorMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          },
        );
      }
    });
  }

  closeDrawer() {
    this.triggerIsToggle.emit(false);
    document.body.style.overflow = 'scroll';
  }

  openAttachedFile(): void {
    const dialogRef = this.dialogService.open({
      content: AttachTaskFilePopupComponent,
      width: 500,
      cssClass: 'attach-files-popup',
    });

    dialogRef.content.instance.taskId = this.taskId;
    dialogRef.content.instance.taskName = this.taskForm.controls.taskName.value;
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
}
