import { ListService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { CreateUpdateCategoryDto } from '@project';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from 'projects/project-service/src/lib/proxy/project-service';
import { NotificationTextMessage } from 'src/app/enum/notification';
import { CommonService } from 'src/core/services';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent {
  categoryForm: FormGroup;
  isEdit = false;
  @Input() categoryId: any;
  headerText: string;
  submitButtonText: string;

  constructor(
    public dialogRef: DialogRef,
    private fb: FormBuilder,
    private categoryService: CategoryService,
    private toasterService: ToasterService,
    public readonly list: ListService,
    public commonService: CommonService,
    private spinnerService: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.setForm();
  }

  setOnLoadData(): void {
    this.headerText = this.categoryId === undefined ? 'Create a new Category' : 'Update Category';
    this.submitButtonText = this.categoryId === undefined ? 'Create Category' : 'Update Category';
    if (this.categoryId > 0) {
      this.spinnerService.show();
      this.getDataByCategoryId(this.categoryId);
    }
  }

  setForm(): void {
    this.categoryForm = this.fb.group({
      categoryName: ['', [Validators.required, Validators.maxLength(50)]],
    });
  }

  getDataByCategoryId(categoryId): void {
    this.categoryService.get(categoryId).subscribe(res => {
      this.categoryForm.patchValue({
        categoryName: res.categoryName,
      });
      this.spinnerService.hide();
    });
  }

  onSaveCategory(): void {
    if (this.categoryForm.invalid) {
      this.categoryForm.controls.categoryName.markAsTouched();
      return;
    }

    this.spinnerService.show();

    const param: CreateUpdateCategoryDto = {
      categoryName: this.categoryForm.value.categoryName,
    };

    if (this.categoryId !== null && this.categoryId !== undefined) {
      this.categoryService.update(this.categoryId, param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.commonService.onDialogClose(this.dialogRef, true);
            this.toasterService.success(
              NotificationTextMessage.categoryUpdatedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    } else {
      this.categoryService.create(param).subscribe(
        res => {
          if (res) {
            this.spinnerService.hide();
            this.commonService.onDialogClose(this.dialogRef, true);
            this.toasterService.success(
              NotificationTextMessage.categoryAddedMessage,
              '',
              this.commonService.toasterMessageConfiguration,
            );
          }
        },
        err => {
          this.spinnerService.hide();
        },
      );
    }
  }

  onCloseDialog(): void {
    this.commonService.onDialogClose(this.dialogRef, false);
  }
}
