import type { CreateUpdateTagsDto, TagsDto, TagsGetListInput } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  apiName = 'ProjectService';

  create = (input: CreateUpdateTagsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TagsDto>(
      {
        method: 'POST',
        url: '/api/tags',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/tags/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TagsDto>(
      {
        method: 'GET',
        url: `/api/tags/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getAllList = (input: TagsGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TagsDto[]>(
      {
        method: 'GET',
        url: '/api/tags/getalllist',
        params: {
          name: input.name,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: TagsGetListInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TagsDto>>(
      {
        method: 'GET',
        url: '/api/tags/getall',
        params: {
          name: input.name,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateTagsDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TagsDto>(
      {
        method: 'PUT',
        url: `/api/tags/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
