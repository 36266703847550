import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  CreateMultipleTaskFileDto,
  CreateUpdateTaskFileDto,
  TaskFileDto,
} from '../dtos/models';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  apiName = 'TaskService';

  delete = (taskId: number, fileId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/task/files/${taskId}/files/${fileId}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (taskId: number, fileId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskFileDto>(
      {
        method: 'GET',
        url: `/api/task/files/${taskId}/files/${fileId}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (taskId: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskFileDto[]>(
      {
        method: 'GET',
        url: `/api/task/files/${taskId}/files`,
      },
      { apiName: this.apiName, ...config },
    );

  save = (input: CreateUpdateTaskFileDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskFileDto>(
      {
        method: 'POST',
        url: '/api/task/files',
        body: input.fileStreamContent,
      },
      { apiName: this.apiName, ...config },
    );

  saveMultiple = (input: CreateMultipleTaskFileDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskFileDto[]>(
      {
        method: 'POST',
        url: '/api/task/files/saveMultipleFiles',
        body: input.fileStreamContents,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
