<div *ngIf="isRender">
  <div class="d-flex justify-content-between drawer-header align-items-center">
    <h4 class="title">Task Details</h4>
    <a class="close-icon" (click)="closeDrawer()"><i class="far fa-xmark"></i></a>
  </div>
  <div class="drawer-content">
    <form [formGroup]="taskForm" validateOnSubmit class="k-form k-form-md">
      <div class="d-flex align-items-center mb-20">
        <div class="display-inline" [ngClass]="{ 'curser-not-allowd': isAllSubTaskCompleted }">
          <input
            [disabled]="isAllSubTaskCompleted"
            [checked]="this.taskForm.controls.taskStatus?.value === taskStatusType.Completed"
            type="checkbox"
            kendoCheckBox
            class="billable-checkbox mr-5"
            size="large"
            (change)="onTaskCompleteChange()"
          />
        </div>
        <kendo-dropdownbutton
          [iconClass]="getIconClass(this.taskForm.controls.taskType.value)"
          [data]="taskTypeList"
          textField="name"
          valueField="id"
          (itemClick)="setTaskTypeValue($event)"
          [popupSettings]="{ popupClass: popupClass }"
        >
          <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
            <div
              class="dropdown-menu-links"
              [ngClass]="{
                taskTypeSelected:
                  this.taskForm.controls.taskType.value === dataItemDropDownButton.id
              }"
            >
              <i class="{{ dataItemDropDownButton.iconClass }} dropdown-item-color mr-5"></i>
              {{ dataItemDropDownButton.name }}
            </div>
          </ng-template>
        </kendo-dropdownbutton>
        <kendo-textbox
          formControlName="taskName"
          class="k-input-inner-text-box"
          aria-placeholder="Give your task a name"
        ></kendo-textbox>
      </div>
      <div class="mb-20">
        <kendo-editor
          style="height: 150px"
          class="mb-20 kendo-editor"
          placeholder="| Add a description"
          [iframeCss]="{ content: customStyleContent }"
          formControlName="description"
        ></kendo-editor>
      </div>
      <div>
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-user mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Assignee</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col d-flex align-items-center">
            <div
              *ngIf="!isShowAssigneeMultiSelect"
              (click)="
                isShowAssigneeMultiSelect = true;
                isShowDueDateInput = false;
                isShowPriorityInput = false
              "
            >
              <div
                *ngIf="this.taskForm.controls.assignee?.value.length === 0; else assigneeTemplate"
                class="d-flex align-items-center"
              >
                <i class="fal fa-circle-user mr-10 font-size-22"></i>
              </div>
              <ng-template #assigneeTemplate>
                <ng-container *ngFor="let user of this.taskForm.controls.assignee.value">
                  <kendo-avatar
                    rounded="full"
                    size="large"
                    [border]="true"
                    [initials]="getInitials(user).avatar"
                    [style.backgroundColor]="getInitials(user).generateAvtarColor"
                    class="social-profile-text assignee-profile"
                  ></kendo-avatar>
                </ng-container>
              </ng-template>
            </div>
            <kendo-multiselect
              *ngIf="isShowAssigneeMultiSelect"
              [clearButton]="false"
              [checkboxes]="true"
              [data]="assigneeList"
              valueField="userId"
              textField="userName"
              [ngModelOptions]="{ standalone: true }"
              formControlName="assignee"
              (close)="isShowAssigneeMultiSelect = false"
            ></kendo-multiselect>
          </div>
        </div>

        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-calendar mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Due Date</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col">
            <div
              *ngIf="!isShowDueDateInput"
              (click)="
                isShowAssigneeMultiSelect = false;
                isShowDueDateInput = true;
                isShowPriorityInput = false
              "
            >
              <div
                *ngIf="
                  this.taskForm.controls.dueDate?.value === '' ||
                    this.taskForm.controls.dueDate?.dueDate === null;
                  else dueDate
                "
              >
                <i class="fal fa-calendar-circle-plus font-size-18"></i>
              </div>
              <ng-template #dueDate>
                <div>
                  {{ this.taskForm.controls.dueDate?.value | date: 'dd-MMM-yyyy' }}
                </div>
              </ng-template>
            </div>

            <kendo-datepicker
              *ngIf="isShowDueDateInput"
              placeholder="Choose a date ..."
              [format]="'EEE, d MMM yyyy'"
              class="w-260"
              formControlName="dueDate"
              (close)="isShowDueDateInput = false"
              calendarType="classic"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="d-flex-row mb-20">
          <div class="d-flex-col-lg-5 d-flex-col">
            <kendo-label class="label-text"
              ><i class="far fa-flag mr-10 font-size-16 label-text-icon"></i
              ><span class="font-size-14">Priority</span></kendo-label
            >
          </div>
          <div class="d-flex-col-lg-7 d-flex-col">
            <div
              *ngIf="!isShowPriorityInput"
              (click)="
                isShowAssigneeMultiSelect = false;
                isShowDueDateInput = false;
                isShowPriorityInput = true
              "
            >
              <div
                class="status-container priority-{{
                  priorityEnum[this.taskForm.controls.priority.value]
                }}"
              >
                <span class="indicator mr-5"></span>
                {{ priorityEnum[this.taskForm.controls.priority?.value] }}
              </div>
            </div>
            <kendo-dropdownlist
              *ngIf="isShowPriorityInput"
              [data]="priorityList"
              textField="text"
              valueField="id"
              [valuePrimitive]="true"
              class="w-200"
              formControlName="priority"
              (close)="isShowPriorityInput = false"
            >
            </kendo-dropdownlist>
          </div>
        </div>
      </div>

      <div class="d-flex-row mb-20">
        <div class="d-flex-col-lg-5 d-flex-col">
          <kendo-label class="label-text">
            <i class="far fa-hourglass mr-10 font-size-16 label-text-icon"></i
            ><span class="font-size-14">Estimate Hours</span></kendo-label
          >
        </div>
        <div class="d-flex-col-lg-7 d-flex-col">
          <div class="d-flex gap-10 flex-wrap">
            <div class="d-flex">
              <kendo-textbox
                formControlName="estimateHour"
                [clearButton]="false"
                size="small"
                maxlength="5"
                [style.width.px]="80"
                class="k-input-inner-text-box"
                aria-placeholder="hour"
                #listName
                data-field="estimateHour"
                (keydown)="commonService.onKeyDownForEstHour($event)"
                (paste)="onPaste($event)"
              ></kendo-textbox>
              <span class="m-auto ml-5">/h</span>
            </div>
            <div class="d-flex">
              <kendo-textbox
                formControlName="estimateMinute"
                [clearButton]="false"
                size="small"
                [style.width.px]="80"
                class="k-input-inner-text-box"
                aria-placeholder="minuts"
                #listName
                data-field="estimateMinute"
                (keydown)="commonService.onKeyDownForEstMinutes($event)"
                (paste)="onPaste($event)"
              ></kendo-textbox>
              <span class="m-auto ml-5">/m</span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div>
        <kendo-label labelCssClass="k-form-label font-size-16 mb-5" text="Sub Tasks"></kendo-label>
        <div class="grid-wrapper">
          <kendo-grid
            [data]="subtaskList"
            (edit)="onEditSubTask($event)"
            (cancel)="onCancelSubTask($event)"
            (save)="onSaveSubTask($event)"
            (add)="onAddSubTask($event)"
            (remove)="onRemoveSubTask($event)"
            class="add-sub-task-grid"
          >
            <ng-template kendoGridToolbarTemplate [position]="'bottom'">
              <a
                kendoGridAddCommand
                class="add-sub-task"
                [ngClass]="{ 'mt-10 pl-10': subtaskList.length > 0 }"
                ><i class="far fa-plus pr-5"></i>Add new SubTask</a
              >
            </ng-template>
            <ng-template kendoGridNoRecordsTemplate>
              <div class="display-none"></div>
            </ng-template>
            <kendo-grid-column field="taskName">
              <ng-template kendoGridHeaderTemplate></ng-template>
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="d-flex gap-10 align-items-center">
                  <input
                    [disabled]="checkIsSubDisable(dataItem)"
                    [checked]="dataItem.taskStatus === taskStatusType.Completed"
                    type="checkbox"
                    kendoCheckBox
                    class="billable-checkbox mr-5"
                    size="large"
                    (change)="onSubTaskCompleteChange(dataItem, rowIndex)"
                  />
                  {{ dataItem.taskName }}
                </div>
              </ng-template>
              <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset d-flex gap-5">
                    <kendo-textbox
                      [formControl]="formGroup.get('taskName')"
                      class="k-input-inner-text-box"
                      placeholder="Enter Task Name for SubTask"
                    ></kendo-textbox>
                  </fieldset>
                </form>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-command-column [width]="110">
              <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem="dataItem">
                <ul class="grid-action-list grid-command-action">
                  <div class="d-flex gap-5 justify-content-end">
                    <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                      <a
                        title="Save"
                        class="grid-command-action-links"
                        kendoGridSaveCommand
                        [disabled]="formGroup?.invalid"
                      >
                        <i class="far fa-floppy-disk"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Discard change"
                        class="grid-command-action-links"
                        kendoGridCancelCommand
                      >
                        <i class="far fa-ban"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Edit"
                        class="grid-command-action-links"
                        kendoGridEditCommand
                        *ngIf="!isNew"
                      >
                        <i class="far fa-pen"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        title="Delete"
                        class="grid-command-action-links"
                        kendoGridRemoveCommand
                        *ngIf="!isNew"
                      >
                        <i class="far fa-trash-alt"></i>
                      </a>
                    </li>
                    <li *ngIf="!isNew && dataItem.id !== 0" (click)="getSubTaskDataById(dataItem)">
                      <a class="grid-command-action-links" title="More Action">
                        <i class="far fa-circle-ellipsis"></i
                      ></a>
                    </li>
                  </div>
                </ul>
              </ng-template>
            </kendo-grid-command-column>
          </kendo-grid>
        </div>
      </div>
      <hr />
      <div>
        <kendo-tabstrip class="assignee-tree">
          <kendo-tabstrip-tab title="Time logs" [selected]="true">
            <ng-template kendoTabContent>
              <div
                *ngIf="timeLogList.length === 0; else gridTemplate"
                class="d-flex justify-content-center align-items-center flex-column mt-4"
              >
                <img src="assets/images/timelog.png" alt="timelog" class="mb-10" />
                <p class="mt-5">No Time Logged!</p>
                <p class="text-secondary-text font-size-12">
                  It seems there is no time logged on this task.
                </p>
                <a
                  title="Add Task Timelog"
                  class="add-on-btn"
                  (click)="openTimeLog(this.taskForm.controls.taskName.value)"
                  ><i class="far fa-plus"></i> Log time on task
                </a>
              </div>
              <ng-template #gridTemplate>
                <div>
                  <div class="d-flex gap-20 primary-card text-primary flex-wrap mb-24 font-size-12">
                    <div>
                      <i class="fa-regular fa-clock"></i>
                      <span class="ml-5 fw-500">logged</span> <span>: {{ totalLoggedTime }}</span>
                    </div>
                    <div>
                      <i class="fa-solid fa-ban"></i>
                      <span class="ml-5 fw-500">Non-billable</span
                      ><span>: {{ totalLoggedNonBillableTime }}</span>
                    </div>
                    <div>
                      <i class="fa-regular fa-circle-check"></i>
                      <span class="ml-5 fw-500">Billable</span
                      ><span> : {{ totalLoggedBillableTime }} </span>
                    </div>
                  </div>
                  <div class="grid-wrapper mb-15">
                    <kendo-grid
                      [kendoGridBinding]="timeLogList"
                      [sortable]="true"
                      [sort]="sort"
                      (sortChange)="sortChange($event)"
                      class="timelog-grid"
                    >
                      <kendo-grid-column field="timelogDatetime" title="Date" [width]="120">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'yyyy-MM-dd' }}">{{
                            dataItem.timelogDatetime | date: 'dd-MM-yyyy'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="name"
                        title="Created By"
                        [width]="150"
                        [sortable]="false"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center gap-10">
                            <kendo-avatar
                              rounded="full"
                              size="large"
                              [border]="true"
                              [initials]="commonService.getInitials(this.createdBy)"
                              [style.backgroundColor]="
                                commonService.generateAvtarColor(this.creatorId, this.createdBy)
                              "
                              class="social-profile-text task-detail-avatar"
                            ></kendo-avatar>
                            <span
                              class="clamp"
                              title="{{ getCreatedByName(dataItem.creatorId) }}"
                              >{{ getCreatedByName(dataItem.creatorId) }}</span
                            >
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column
                        field="timelogDescription"
                        title="Description"
                        [width]="150"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDescription }}">{{
                            dataItem.timelogDescription
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogDatetime" title="Start" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogDatetime | date: 'shortTime' }}">{{
                            dataItem.timelogDatetime | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="end" title="End" [sortable]="false" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span>{{
                            commonService.getEndDate(
                              dataItem.timelogDatetime,
                              dataItem.timelogMinutes
                            ) | date: 'shortTime'
                          }}</span>
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogIsBillable" title="Billable" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <input
                            type="checkbox"
                            kendoCheckBox
                            [checked]="dataItem.timelogIsBillable === 1 ? true : false"
                            class="billable-checkbox grid-checkbox"
                            size="large"
                            disabled
                          />
                        </ng-template>
                      </kendo-grid-column>
                      <kendo-grid-column field="timelogMinutes" title="Time" [width]="80">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.timelogMinutes }}m"
                            >{{ dataItem.timelogMinutes }}m</span
                          >
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column
                        field="hours"
                        title="Hours"
                        [sortable]="false"
                        [width]="80"
                      >
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <span title="{{ dataItem.hours }}">{{ dataItem.hours }}</span>
                        </ng-template>
                      </kendo-grid-column>

                      <kendo-grid-column field="" title="" [width]="100">
                        <ng-template kendoGridCellTemplate let-dataItem>
                          <div class="d-flex align-items-center justify-content-between">
                            <ul class="grid-action-list">
                              <div class="d-flex gap-10">
                                <li>
                                  <a title="Edit" (click)="onEdit(dataItem)">
                                    <i class="far fa-pen"></i>
                                  </a>
                                </li>
                                <li *ngIf="!dataItem.isDefault">
                                  <a title="Delete" (click)="onDelete(dataItem)">
                                    <i class="far fa-trash-alt"></i>
                                  </a>
                                </li>
                              </div>
                            </ul>
                          </div>
                        </ng-template>
                      </kendo-grid-column>
                    </kendo-grid>
                  </div>
                  <ng-container *ngIf="timeLogList && timeLogList.length > 0">
                    <a
                      title="Add Task Timelog"
                      class="add-on-btn display-inline"
                      (click)="openTimeLog(taskForm.controls.taskName.value)"
                    >
                      <i class="far fa-plus"></i> Log time on task
                    </a>
                  </ng-container>
                </div>
              </ng-template>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Files" *ngIf="false">
            <ng-template kendoTabContent>
              <div class="d-flex justify-content-center align-items-center flex-column mt-4">
                <img src="assets/images/no-files.png" alt="timelog" class="mb-10" />
                <p class="mt-5">No files are attached.</p>
                <p class="text-secondary-text font-size-12">
                  It seems there are no files attached on this task.
                </p>
                <a title="AP Timelog" class="add-on-btn" (click)="openAttachedFile()"
                  ><i class="far fa-plus"></i> Attach files
                </a>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </form>
  </div>
  <div class="drawer-footer d-flex justify-content-end gap-15">
    <button kendoButton fillMode="outline" (click)="closeDrawer()">Cancel</button>
    <button kendoButton (click)="onSave()">Save</button>
  </div>
</div>
