<kendo-dialog-titlebar (close)="onCancelAction()">
  <div style="font-size: 18px; line-height: 1.3em">
    <span class="k-icon k-button-icon k-i-form-element"></span> Add Tags
  </div>
</kendo-dialog-titlebar>

<div>
  <form class="k-form" [formGroup]="formGroup">
    <kendo-formfield>
      <kendo-autocomplete
        formControlName="tagName"
        [data]="displayTagList"
        placeholder="search Tags"
        [valueField]="'name'"
        [textField]="'name'"
        (valueChange)="onConfirmAction()"
      >
      </kendo-autocomplete>
    </kendo-formfield>
  </form>
</div>

<!-- <kendo-dialog-actions>
  <button kendoButton (click)="onCancelAction()">Cancel</button>
</kendo-dialog-actions> -->
