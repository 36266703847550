import { ListService, PagedResultDto } from '@abp/ng.core';
import { IdentityUserDto, IdentityUserService } from '@abp/ng.identity/proxy';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-add-people-team',
  templateUrl: './add-people-team.component.html',
  styleUrls: ['./add-people-team.component.css'],
})
export class AddPeopleTeamComponent {
  public projectOwnerList: Array<string> = [];

  // public peopleList = [
  //   {
  //     id: 1,
  //     name: 'Company Name',
  //     managerId: null,
  //   },
  //   {
  //     id: 2,
  //     name: 'Sonali',
  //     managerId: 1,
  //   },
  //   {
  //     id: 3,
  //     name: 'Rohan',
  //     managerId: 1,
  //   },
  //   {
  //     id: 3,
  //     name: 'Ishan',
  //     managerId: 1,
  //   },
  // ];

  public teamList = [
    {
      id: 1,
      name: 'Administrative Team',
      managerId: null,
    },
    {
      id: 2,
      name: 'Sonali',
      managerId: 1,
    },
    {
      id: 3,
      name: 'Rohan',
      managerId: 1,
    },
    {
      id: 3,
      name: 'Ishan',
      managerId: 1,
    },
  ];
  peopleList = { items: [], totalCount: 0 } as PagedResultDto<IdentityUserDto>;
  usersArr: any[] = [];
  @Output() dataEvent = new EventEmitter<string[]>();

  constructor(
    protected service: IdentityUserService,
    public readonly list: ListService,
  ) {}

  ngOnInit(): void {
    this.getPeopleList();
  }

  getPeopleList(): void {
    const bookStreamCreator = query => this.service.getList(query);
    this.list.hookToQuery(bookStreamCreator).subscribe(response => {
      this.peopleList = response;
    });
  }

  onSelectionChange(event) {
    if (event.action === 'select') {
      event.items.forEach(element => {
        this.usersArr.push(element.dataItem.id);
      });
    } else if (event.action === 'remove') {
      event.items.forEach(element => {
        const indexToRemove = this.usersArr.indexOf(element.dataItem.id);
        this.usersArr.splice(indexToRemove, 1);
      });
    }
    this.dataEvent.emit(this.usersArr);
  }
}
