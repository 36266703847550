<div class="q-content">
  <div class="q-content-bg calculate-height">
    <div class="q-content-header">
      <div class="border-bottom project-heading" *ngIf="projectName !== ''">
        <div class="project-name d-flex">
          <p class="m-0 font-size-18 fw-500">
            <kendo-avatar
              size="large"
              [border]="true"
              [initials]="commonService.getInitials(projectName)"
              class="project-logo"
            ></kendo-avatar
            ><span class="display-inline w-260 clamp vertical-align-middle">{{ projectName }}</span>
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-between flex-wrap gap-20 mb-24">
        <kendo-textbox
          placeholder="Search for task list / task name"
          [(ngModel)]="searchText"
          [ngModelOptions]="{ standalone: true }"
          (keyup.enter)="getTaskListList()"
          (ngModelChange)="onSearchTextChange($event)"
          class="search"
        >
          <ng-template kendoTextBoxPrefixTemplate>
            <i class="fas fa-search k-icon font-size-14"></i>
          </ng-template>
        </kendo-textbox>
        <div class="d-flex gap-15 align-items-center">
          <kendo-button kendoButton (click)="onAddTaskList()"
            ><i class="far fa-plus pr-5"></i>Add Task List
          </kendo-button>
          <div style="display: none">
            <kendo-dropdownlist [data]="statusList"></kendo-dropdownlist>
            <a class="flat-icon-container"><i class="fa-light fa-bars-filter"></i></a>
            <a class="flat-icon-container"><i class="fas fa-ellipsis-h"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-wrapper task-list-grid" *ngIf="taskListsList.length > 0">
      <kendo-expansionpanel
        *ngFor="let item of taskListsList; index as i"
        [expanded]="currentExpandedPanelId === item.id"
        (action)="onTaskListsListExpandedPanelClick(i, item)"
        [svgExpandIcon]="commonService.arrowDown"
        [svgCollapseIcon]="commonService.arrowUp"
      >
        <ng-template kendoExpansionPanelTitleDirective>
          <div
            class="d-flex align-items-center justify-content-between expansion-panel-title-wrapper wp-100 flex-wrap"
          >
            <div class="d-flex flex-wrap align-items-center">
              <span class="font-size-16 mr-1">{{ item.name }}</span>
              <span class="blue-label">{{ item.totalNumberOfTask }} Tasks</span>
              <kendo-dropdownbutton
                [data]="taksListActionItem"
                class="dropdown-button ml-10"
                (itemClick)="ontaskListAxctionClick($event, item.id, item.name)"
                (click)="stopPropagation($event)"
                ><i class="fas fa-ellipsis-v"></i>
              </kendo-dropdownbutton>
            </div>
            <div>
              <div class="blue-label gap-5">
                <span>
                  <i class="fa-solid fa-hourglass mr-5"></i>
                  {{ item.totalEstimatedTime }}
                </span>
                <span>/</span>
                <span>
                  <i class="fa-regular fa-clock mr-5"></i>
                  {{ item.totalTimelogged }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
        <div class="grid-wrapper">
          <kendo-treelist
            kendoTreeListExpandable
            [kendoTreeListFlatBinding]="taskList"
            idField="id"
            [fetchChildren]="fetchChildren"
            [hasChildren]="hasChildren"
            (add)="addTaskHandler($event)"
            (edit)="editTaskHandler($event)"
            (remove)="removeTaskHandler($event)"
            (save)="saveTaskHandler($event, item.id)"
            (cancel)="cancelTaskHandler($event)"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
          >
            <kendo-treelist-column
              field="name"
              title="Task / Bug Name"
              [width]="300"
              [expandable]="true"
            >
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup" validateOnSubmit>
                  <fieldset class="k-form-fieldset d-flex gap-5">
                    <kendo-dropdownlist
                      [data]="taskTypeList"
                      textField="name"
                      valueField="id"
                      [valuePrimitive]="true"
                      [formControl]="formGroup.get('taskType')"
                      class="w-200 h-fit-content"
                    >
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <i class="{{ dataItem.iconClass }}"></i>{{ dataItem.name }}
                      </ng-template>
                    </kendo-dropdownlist>
                    <kendo-formfield class="mt-0">
                      <kendo-textbox
                        [formControl]="formGroup.get('taskName')"
                        class="k-input-inner-text-box"
                        aria-placeholder="Give your project a name"
                        #listName
                        (keydown.enter)="
                          saveTaskHandler(
                            {
                              sender: sender,
                              dataItem: dataItem,
                              parent: parent,
                              formGroup: formGroup,
                              isNew: isNew
                            },
                            item.id
                          )
                        "
                      ></kendo-textbox>
                      <kendo-formerror *ngIf="formGroup.controls.taskName.errors?.maxlength"
                        >Error: Task Name cannot be more than 255 character!</kendo-formerror
                      >
                      <kendo-formerror *ngIf="formGroup.controls.taskName.errors?.required"
                        >Error: Task Name is required!</kendo-formerror
                      >
                    </kendo-formfield>
                  </fieldset>
                </form>
              </ng-template>

              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div class="display-inline">
                  <div
                    class="display-inline"
                    [ngClass]="{ 'curser-not-allowd': checkIsDisable(dataItem) }"
                  >
                    <input
                      [disabled]="checkIsDisable(dataItem)"
                      [checked]="dataItem.taskStatus === taskStatusType.Completed"
                      type="checkbox"
                      kendoCheckBox
                      class="billable-checkbox mr-5"
                      size="large"
                      (change)="onTaskCompleteChange(dataItem)"
                    />
                  </div>
                  <kendo-dropdownbutton
                    [iconClass]="getIconClass(dataItem.taskType)"
                    [data]="taskTypeList"
                    textField="name"
                    (itemClick)="onTaskTypeClick($event, dataItem, item.id)"
                    [popupSettings]="{ popupClass: popupClass }"
                  >
                    <ng-template kendoDropDownButtonItemTemplate let-dataItemDropDownButton>
                      <div
                        class="dropdown-menu-links"
                        [ngClass]="{
                          taskTypeSelected: dataItem.taskType === dataItemDropDownButton.id
                        }"
                      >
                        <i
                          class="{{ dataItemDropDownButton.iconClass }} dropdown-item-color mr-5"
                        ></i>
                        {{ dataItemDropDownButton.name }}
                      </div>
                    </ng-template>
                  </kendo-dropdownbutton>
                  <a (click)="onGoToTaskDetailsClick(dataItem)" title="{{ dataItem.taskName }}"
                    ><span class="clamp text-underline w-200">{{ dataItem.taskName }}</span></a
                  >
                </div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column
              field="assignee"
              title="Assignee"
              [width]="200"
              [sortable]="false"
            >
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset">
                    <kendo-multiselect
                      [clearButton]="false"
                      [checkboxes]="true"
                      [data]="assigneeList"
                      valueField="userId"
                      textField="userName"
                      [ngModelOptions]="{ standalone: true }"
                      [formControl]="formGroup.get('assignee')"
                      (keydown.enter)="
                        saveTaskHandler(
                          {
                            sender: sender,
                            dataItem: dataItem,
                            parent: parent,
                            formGroup: formGroup,
                            isNew: isNew
                          },
                          item.id
                        )
                      "
                    ></kendo-multiselect>
                  </fieldset>
                </form>
              </ng-template>
              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div *ngIf="dataItem.assignee && dataItem.assignee.length > 0; else noAssignee">
                  <ng-container *ngFor="let user of dataItem.assignee">
                    <kendo-avatar
                      rounded="full"
                      size="large"
                      [border]="true"
                      [initials]="getInitials(user).avatar"
                      [style.backgroundColor]="getInitials(user).generateAvtarColor"
                      class="social-profile-text assignee-container"
                    ></kendo-avatar>
                  </ng-container>
                </div>
                <ng-template #noAssignee>
                  <i class="fal fa-circle-user font-size-24"></i>
                </ng-template>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="dueDate" title="Due Date" [width]="150">
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset">
                    <kendo-datepicker
                      placeholder="Choose a date ..."
                      [format]="'EEE, d MMM yyyy'"
                      [formControl]="formGroup.get('dueDate')"
                      [value]="dueDateDefaultValue"
                      calendarType="classic"
                      (keydown.enter)="
                        saveTaskHandler(
                          {
                            sender: sender,
                            dataItem: dataItem,
                            parent: parent,
                            formGroup: formGroup,
                            isNew: isNew
                          },
                          item.id
                        )
                      "
                    ></kendo-datepicker>
                  </fieldset>
                </form>
              </ng-template>
              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div *ngIf="dataItem.dueDate === '' || dataItem.dueDate === null; else dueDate">
                  <i class="fal fa-calendar-circle-plus font-size-20"></i>
                </div>
                <ng-template #dueDate>
                  <div>
                    {{ dataItem.dueDate | date: 'dd-MMM-yyyy' }}
                  </div>
                </ng-template>
                <div></div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="priority" title="Priority" [width]="150">
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset">
                    <kendo-dropdownlist
                      [data]="priorityList"
                      textField="text"
                      valueField="id"
                      [valuePrimitive]="true"
                      [formControl]="formGroup.get('priority')"
                      (keydown.enter)="
                        saveTaskHandler(
                          {
                            sender: sender,
                            dataItem: dataItem,
                            parent: parent,
                            formGroup: formGroup,
                            isNew: isNew
                          },
                          item.id
                        )
                      "
                    >
                    </kendo-dropdownlist>
                  </fieldset>
                </form>
              </ng-template>
              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div
                  *ngIf="dataItem.priority"
                  class="status-container priority-{{ priorityEnum[dataItem.priority] }}"
                >
                  <span class="indicator mr-5"></span>
                  {{ priorityEnum[dataItem.priority] }}
                </div>
                <div
                  *ngIf="!dataItem.priority"
                  class="status-container priority-{{ priorityEnum[dataItem.priority] }}"
                >
                  <span class="indicator mr-5"></span>
                  {{ priorityEnum[dataItem.priority] }}
                </div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="comments" title="Comments" [width]="170" [hidden]="true">
              <ng-template
                kendoTreeListCellTemplate
                let-dataItem
                let-isNew="isNew"
                let-cellContext="cellContext"
              >
                <div><i class="far fa-comment mr-10"></i>{{ dataItem.comments }}</div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column field="estimateMinutes" title="Estimate Hours" [width]="170">
              <ng-template
                kendoTreeListEditTemplate
                let-dataItem="dataItem"
                let-column="column"
                let-formGroup="formGroup"
                let-isNew="isNew"
              >
                <form class="k-form k-form-md" [formGroup]="formGroup">
                  <fieldset class="k-form-fieldset d-flex gap-5">
                    <kendo-textbox
                      [formControl]="formGroup.get('estimateHour')"
                      [clearButton]="false"
                      class="k-input-inner-text-box"
                      aria-placeholder="hour"
                      #listName
                      data-field="estimateHour"
                      maxlength="5"
                      (keydown)="commonService.onKeyDownForEstHour($event)"
                      (keydown.enter)="
                        saveTaskHandler(
                          {
                            sender: sender,
                            dataItem: dataItem,
                            parent: parent,
                            formGroup: formGroup,
                            isNew: isNew
                          },
                          item.id
                        )
                      "
                      (paste)="onPaste($event)"
                    ></kendo-textbox>
                    <span class="m-auto">/h</span>
                    <kendo-textbox
                      [formControl]="formGroup.get('estimateMinut')"
                      [clearButton]="false"
                      class="k-input-inner-text-box"
                      aria-placeholder="minuts"
                      #listName
                      data-field="estimateMinut"
                      (keydown)="commonService.onKeyDownForEstMinutes($event)"
                      (keydown.enter)="
                        saveTaskHandler(
                          {
                            sender: sender,
                            dataItem: dataItem,
                            parent: parent,
                            formGroup: formGroup,
                            isNew: isNew
                          },
                          item.id
                        )
                      "
                      (paste)="onPaste($event)"
                    ></kendo-textbox>
                    <span class="m-auto">/m</span>
                  </fieldset>
                </form>
              </ng-template>
              <ng-template kendoTreeListCellTemplate let-dataItem>
                <div>
                  {{ commonService.getHoursAndMinutesFromTotalMinutes(dataItem.estimateMinutes) }}
                </div>
              </ng-template>
            </kendo-treelist-column>
            <kendo-treelist-column [width]="95">
              <ng-template
                kendoTreeListCellTemplate
                let-dataItem
                let-isNew="isNew"
                let-cellContext="cellContext"
              >
                <ul class="grid-action-list grid-command-action d-flex justify-content-end">
                  <li>
                    <a
                      [kendoTreeListAddCommand]="cellContext"
                      [svgIcon]="commonService.addExpressionIcon"
                      title="Add SubTask"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li>
                    <a
                      [kendoTreeListEditCommand]="cellContext"
                      [svgIcon]="commonService.pencilIcon"
                      title="Edit"
                      [primary]="true"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li *ngIf="!checkIsDisable(dataItem)">
                    <a
                      [kendoTreeListRemoveCommand]="cellContext"
                      [svgIcon]="commonService.trashIcon"
                      title="Remove"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li [ngClass]="{ 'curser-not-allowd': formGroup?.invalid }">
                    <a
                      [kendoTreeListSaveCommand]="cellContext"
                      [disabled]="formGroup?.invalid"
                      [svgIcon]="commonService.saveIcon"
                      title="{{ isNew ? 'Add' : 'Update' }}"
                      class="grid-command-action-links"
                    ></a>
                  </li>

                  <li>
                    <a
                      [kendoTreeListCancelCommand]="cellContext"
                      [svgIcon]="commonService.cancelIcon"
                      title="{{ isNew ? 'Discard changes' : 'Cancel' }}"
                      class="grid-command-action-links"
                    ></a>
                  </li>
                  <li *ngIf="!isNew && dataItem.id !== 0" (click)="addMoreDetails(dataItem)">
                    <a class="grid-command-action-links" title="More Action">
                      <i class="far fa-circle-ellipsis"></i
                    ></a>
                  </li>
                </ul>
              </ng-template>
            </kendo-treelist-column>
            <ng-template kendoTreeListToolbarTemplate [position]="'bottom'">
              <button kendoTreeListAddCommand type="button" class="add-new-btn">
                <i class="far fa-plus mr-5"></i> Add new
              </button>
            </ng-template>
          </kendo-treelist>
        </div>
      </kendo-expansionpanel>
    </div>
    <div
      *ngIf="taskListsList.length === 0 && isNoRecordFound"
      class="no-taskList d-flex align-items-center justify-content-center flex-column"
    >
      <img src="assets/images/no-tasklist.png" alt="no-taskList" />
      <p>No task-list found</p>
      <p class="desc">It seems there are no task-lists found for this project.</p>
    </div>
  </div>
  <div kendoDialogContainer></div>
</div>

<kendo-drawer-container class="add-more-details">
  <kendo-drawer
    [mode]="expandMode"
    [expanded]="isDrawerOpened"
    [position]="position"
    [autoCollapse]="false"
  >
    <ng-template kendoDrawerTemplate>
      <app-add-task-more-details
        [taskId]="taskId"
        [projectId]="projectId"
        [assigneeList]="assigneeList"
        [sortingName]="sorting"
        (triggerIsToggle)="checkIsToggle($event)"
      ></app-add-task-more-details>
    </ng-template>
  </kendo-drawer>
</kendo-drawer-container>
