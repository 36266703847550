import { RestService, Rest } from '@abp/ng.core';
import type { ListResultDto, PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { CreateUpdateTaskDto, GetTaskInputDto, TaskDto } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  apiName = 'TaskService';

  create = (input: CreateUpdateTaskDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'POST',
        url: '/api/task',
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  delete = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `/api/task/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  get = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'GET',
        url: `/api/task/${id}`,
      },
      { apiName: this.apiName, ...config },
    );

  getList = (input: GetTaskInputDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TaskDto>>(
      {
        method: 'GET',
        url: '/api/task',
        params: {
          taskListId: input.taskListId,
          getSubTasks: input.getSubTasks,
          includeLoggedTime: input.includeLoggedTime,
          completedOnly: input.completedOnly,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName, ...config },
    );

  getSubTasksByParentTaskIdAndSorting = (
    parentTaskId: number,
    sorting: string,
    config?: Partial<Rest.Config>,
  ) =>
    this.restService.request<any, ListResultDto<TaskDto>>(
      {
        method: 'GET',
        url: `/api/task/${parentTaskId}/subtasks`,
        params: { sorting },
      },
      { apiName: this.apiName, ...config },
    );

  markTaskAsCompleteById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/task/${id}/markascomplete`,
      },
      { apiName: this.apiName, ...config },
    );

  markTaskAsReopenedById = (id: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>(
      {
        method: 'POST',
        url: `/api/task/${id}/markasreopen`,
      },
      { apiName: this.apiName, ...config },
    );

  update = (id: number, input: CreateUpdateTaskDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TaskDto>(
      {
        method: 'PUT',
        url: `/api/task/${id}`,
        body: input,
      },
      { apiName: this.apiName, ...config },
    );

  constructor(private restService: RestService) {}
}
