import { DatePipe } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TaskStatusType } from 'projects/task-service/src/lib/proxy/task-service';
import { DashboardService } from 'projects/task-service/src/lib/proxy/task-service/dashboard';
import { CommonService } from 'src/core/services';
import { Priority } from 'tasks/task/config/src/enums/priority';

@Component({
  selector: 'app-dashboard-count-popup',
  templateUrl: './dashboard-count-popup.component.html',
  styleUrls: ['./dashboard-count-popup.component.scss'],
})
export class DashboardCountPopupComponent {
  headerText: string;
  cardType: any;
  gridData: any[] = [];
  taskStatusType = TaskStatusType;
  isNoRecord = false;
  constructor(
    public dialogRef: DialogRef,
    private dashboardService: DashboardService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    public commonService: CommonService,
  ) {}

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  getTaskTypeIconClass(data: number) {
    return data === 0
      ? 'far fa-clipboard-list font-size-14'
      : 'far fa-bug font-size-14 bug-icon-color';
  }

  getTaskList(): void {
    this.spinnerService.show();
    this.dashboardService.getDashboardTaskListByStatus(this.cardType).subscribe(res => {
      this.spinnerService.hide();
      this.gridData = [];
      res.forEach(x => {
        const data: any = {
          taskName: x.taskName,
          project: x.projectName,
          projectLogo: x.projectLogo,
          dueDate: this.datePipe.transform(x.taskDuedate, 'MMM d, y'),
          priority: Priority[x.taskPriority],
          taskId: x.taskId,
          taskListId: x.taskListId,
          taskType: x.taskType,
          taskStatus: x.taskStatus,
          hasChildren: false,
          isAllSubTaskCompleted: x.isAllSubTaskCompleted,
          taskListName: x.taskListName,
          isHeader: false,
          parentTaskId: x.parentTaskId,
        };
        this.gridData.push(data);
      });
      this.isNoRecord = this.gridData.length > 0 ? false : true;
    });
  }
}
