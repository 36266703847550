import { mapEnumToOptions } from '@abp/ng.core';

export enum ProjectStatusFilter {
  'All Status' = 0,
  'On Time' = 1,
  'Delay' = 2,
}

export enum ProjectListType {
  Project = 0,
  Product = 1,
  Portfolio = 2,
}

export const FilterTypesOptions = mapEnumToOptions(ProjectStatusFilter);
