<div *ngIf="data.entityDisplayName || entityDisplayName">
  <kendo-dialog-titlebar class="m-0" (click)="this.visible = false">
    <div>
      {{ 'AbpPermissionManagement::Permissions' | abpLocalization }} -
      {{ entityDisplayName || data.entityDisplayName }}
    </div>
  </kendo-dialog-titlebar>
  <div class="mt-10">
    <div class="form-check">
      <input
        type="checkbox"
        kendoCheckBox
        #selectAllInAllTabsRef
        [checked]="selectAllTab"
        id="select-all-in-all-tabs"
        name="select-all-in-all-tabs"
        (click)="onClickSelectAll()"
        [disabled]="disabledSelectAllInAllTabs"
      />
      <label for="select-all-in-all-tabs" class="k-checkbox-label">
        {{ 'AbpPermissionManagement::SelectAllInAllTabs' | abpLocalization }}
      </label>
    </div>
    <div>
      <ul class="nav nav-pills flex-column">
        <li *ngFor="let group of data.groups; trackBy: trackByFn" class="nav-item">
          <a
            *ngIf="{ assignedCount: getAssignedCount(group.name) } as count"
            class="nav-link pointer"
            [class.active]="selectedGroup?.name === group?.name"
            (click)="onChangeGroup(group)"
            (select)="setDisabled(group.permissions)"
          >
            <div [class.font-weight-bold]="count.assignedCount">
              {{ group?.displayName }}
              <span *ngIf="count.assignedCount > 0">({{ count.assignedCount }})</span>
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="permission-content mt-10">
      <div class="form-check mb-2">
        <input
          type="checkbox"
          kendoCheckBox
          #selectAllInThisTabsRef
          id="select-all-in-this-tabs"
          name="select-all-in-this-tabs"
          [checked]="selectThisTab"
          [disabled]="disableSelectAllTab"
          (click)="onClickSelectThisTab()"
        />
        <label for="select-all-in-this-tabs" class="ms-2">
          {{ 'AbpPermissionManagement::SelectAllInThisTab' | abpLocalization }}
        </label>
      </div>
      <div
        *ngFor="let permission of selectedGroupPermissions; let i = index; trackBy: trackByFn"
        [ngStyle]="permission.style"
        class="form-check mb-2"
      >
        <input
          type="checkbox"
          kendoCheckBox
          #permissionCheckbox
          [checked]="getChecked(permission.name)"
          [value]="getChecked(permission.name)"
          [attr.id]="permission.name"
          [disabled]="isGrantedByOtherProviderName(permission.grantedProviders)"
          (click)="onClickCheckbox(permission, permissionCheckbox.value)"
        />
        <label [attr.for]="permission.name"></label>
        <label class="form-check-label" class="ms-2" [attr.for]="permission.name"
          >{{ permission.displayName }}
          <ng-container *ngIf="!hideBadges">
            <span
              *ngFor="let provider of permission.grantedProviders"
              class="badge bg-light text-dark"
              >{{ provider.providerName }}: {{ provider.providerKey }}</span
            >
          </ng-container>
        </label>
      </div>
    </div>
  </div>
</div>
<kendo-dialog-actions class="m-0">
  <button kendoButton fillMode="outline" (click)="this.visible = false">
    {{ 'AbpIdentity::Cancel' | abpLocalization }}
  </button>
  <button kendoButton (click)="submit()">
    {{ 'AbpIdentity::Save' | abpLocalization }}
  </button>
</kendo-dialog-actions>
