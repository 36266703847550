<div class="py-5 px-4">
  <div class="d-flex justify-content-between">
    <h3 class="pb-1">Project Categories</h3>

    <button class="btn bg-light" kendoButton (click)="onCloseDialog()">
      <i class="bi bi-x"></i>
    </button>
  </div>
  <div>
    <kendo-treeview
      [nodes]="categoryData.items"
      textField="categoryName"
      kendoTreeViewExpandable
      kendoTreeViewHierarchyBinding
      childrenField="parentCategoryId"
    >
      <ng-template kendoTreeViewNodeTemplate let-dataItem>
        <div class="mb-1 d-flex justify-content-between align-item-center">
          <div><i class="bi bi-folder2"></i>{{ dataItem.categoryName }}</div>

          <div>
            <button kendoButton><i class="bi bi-pencil-fill"></i></button>
            <button kendoButton><i class="bi bi-trash-fill"></i></button>
          </div>
        </div>
      </ng-template>
    </kendo-treeview>
  </div>
  <div class="d-flex justify-content-center mt-3" style="cursor: pointer">
    <button kendoButton (click)="onAddCategory()"><i class="bi bi-plus"></i>Add Category</button>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button kendoButton themeColor="primary">Finished</button>
</div>

<!-- (click)="onOpenAddCategory()" -->
