export enum RolesRights {
  AbpIdentity_Roles = 'AbpIdentity.Roles',
  AbpIdentity_Roles_Create = 'AbpIdentity.Roles.Create',
  AbpIdentity_Roles_Delete = 'AbpIdentity.Roles.Delete',
  AbpIdentity_Roles_ManagePermissions = 'AbpIdentity.Roles.ManagePermissions',
  AbpIdentity_Roles_Update = 'AbpIdentity.Roles.Update',
  AbpIdentity_Users = 'AbpIdentity.Users',
  AbpIdentity_Users_Create = 'AbpIdentity.Users.Create',
  AbpIdentity_Users_Delete = 'AbpIdentity.Users.Delete',
  AbpIdentity_Users_ManagePermissions = 'AbpIdentity.Users.ManagePermissions',
  AbpIdentity_Users_Update = 'AbpIdentity.Users.Update',
  AbpTenantManagement_Tenants = 'AbpTenantManagement.Tenants',
  AbpTenantManagement_Tenants_Create = 'AbpTenantManagement.Tenants.Create',
  AbpTenantManagement_Tenants_Delete = 'AbpTenantManagement.Tenants.Delete',
  AbpTenantManagement_Tenants_ManageConnectionStrings = 'AbpTenantManagement.Tenants.ManageConnectionStrings',
  AbpTenantManagement_Tenants_ManageFeatures = 'AbpTenantManagement.Tenants.ManageFeatures',
  AbpTenantManagement_Tenants_Update = 'AbpTenantManagement.Tenants.Update',
  FeatureManagement_ManageHostFeatures = 'FeatureManagement.ManageHostFeatures',
  SettingManagement_Emailing = 'SettingManagement.Emailing',
  SettingManagement_Emailing_Test = 'SettingManagement.Emailing.Test',
}
