export enum StatusTypeEnum {
  Active = 0,
  InActive = 1,
}

export enum ProjectStatusEnum {
  'All Status' = -1,
  'On Time' = 0,
  'Delay' = 1,
}

export enum ActionTypeEnum {
  Add = 0,
  Edit = 1,
  Delete = 2,
  Export = 3,
}
